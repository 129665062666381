import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import Dropzone from "react-dropzone"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Button,
  Container,
  Row,
  Table,
  Label,
  Input,
  Form,
  ModalHeader,
  ModalBody,
  Modal,
  FormFeedback,
} from "reactstrap"

// TableContainer
import { useMutation, useQueryClient } from "react-query"
import Breadcrumbs from "components/Common/Breadcrumb"
import MiniCards from "./mini-card"
import profile from "assets/images/profile-img.png"
import { getUserProfile } from "store/actions"
import { useAuth } from "hooks/useAuth"
import {
  sendEmailForMFA,
  sendSMSForMFA,
  resetPasswordApi,
  forgotPasswordApi,
  getCurrentUserApi,
  updateMember,
} from "api/auth"
import {
  getMembersByGroupIdApi,
  getNextOfKinApi,
  getMemberByIdApi,
  updateProfileAttachmentApi,
} from "api/member"
import {
  getClaimStatusCountsApi,
  getDependentsCountByMemberIdApi,
  getTransactionCountsApi,
} from "api/report"
import Swal from 'sweetalert2';

export const sumArray = (arr, key) => {
  return arr && arr.length ? arr.reduce((a, b) => a + (b[key] || 0), 0) : 0
}

const ContactsProfile = props => {
  const { memberId } = useAuth()
  const queryClient = useQueryClient()

  document.title = "Profile | SUN Member Profile"
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const membersPerPage = 5
  const [member, setMember] = useState({})
  const { userProfile, onGetUserProfile } = props
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditInputModal, setShowEditInputModal] = useState(false)
  const [selectionMade, setSelectionMade] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [dependentsData, setDependentsData] = useState(0)
  const [claimsCount, setClaimsCount] = useState(0)
  const [totalPremiums, setTotalPremiums] = useState(0)
  const [editFormData, setEditFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  })

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [, setSelectedFiles] = useState([])
  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
    setSelectionMade(true)
  }

  const [logo, setLogo] = useState("")

  const handleImageUpload = async event => {
    const file = event.target.files[0]
    if (file && memberId) {
      try {
        const formData = new FormData()
        formData.append("file", file)
        const response = await updateProfileAttachmentApi(memberId, formData)

        const updatedMemberDetails = await getMemberByIdApi(memberId)

        setLogo(updatedMemberDetails.profileImageUrl)
      } catch (error) {
        console.error("Error uploading image:", error)
      }
    } else {
      console.error("No file selected or memberId not available")
    }
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const prevPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleEditProfilePicture = () => {
    setShowEditInputModal(true)
  }

  const miniCards = [
    {
      title: "Total Premiums",
      iconClass: "bx-check-circle",
      text: totalPremiums || 0,
    },
    { title: "Claims", iconClass: "bx bx-file", text: claimsCount || 0 },
    {
      title: "Dependants",
      iconClass: "bx bx-group",
      text: dependentsData ? sumArray(dependentsData, "totalDependents") : 0,
    },
  ]

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      contact: "",
      code: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      contact: Yup.string().required("Please Enter Your Email or Mobile Number"),
      code: Yup.string().when("codeSent", {
        is: true,
        then: Yup.string().required("Please Enter the Code"),
      }),
      newPassword: Yup.string().when("codeSent", {
        is: true,
        then: Yup.string().required("Please Enter New Password"),
      }),
    }),
    onSubmit: async (values) => {
      try {
        const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.contact);
        const isMobile = /^\d+$/.test(values.contact); // Check if input is a phone number
  
        if (codeSent) {
          const response = await resetPasswordApi({
            userName: values.contact,
            password: values.newPassword,
            code: values.code,
          });
          Swal.fire({
            title: 'Processing...',
            text: 'Resetting your password...',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
  
          if (response.status === "Success") {
            Swal.fire('Reset Password Successful!', 'Your password has been reset successfully.', 'success');
            navigate("/login");
          } else {
            Swal.fire('Reset Password Failed!', 'Please try again.', 'error');
            console.error("Error:", response.message);
          }
        } else {
          let response;
          if (isEmail) {
            response = await sendEmailForMFA(values.contact);
          } else if (isMobile) {
            response = await sendSMSForMFA(values.contact);
          } else {
            Swal.fire('Error!', 'Invalid email or mobile number format.', 'error');
            return;
          }
  
          if (response.status === "Success") {
            Swal.fire('OTP Sent!', 'We have sent a one-time code to your email or mobile number.', 'success');
            setCodeSent(true);
          } else {
            Swal.fire('Failed to Send OTP', 'Please try again.', 'error');
            console.error("Error:", response.message);
          }
        }
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire('Operation Failed', 'An error occurred. Please try again.', 'error');
      }
    },
  });  

  const handleSubmitEditProfile = async e => {
    e.preventDefault()
    try {
      const updatedMember = {
        ...member,
        firstName: editFormData.firstName,
        lastName: editFormData.lastName,
        mobileNumber1: editFormData.mobileNumber1,
      }

      await updateMember(updatedMember)

      localStorage.setItem("user", JSON.stringify(updatedMember))

      setMember(updatedMember)

      setShowEditModal(false)
    } catch (error) {
      console.error("Error updating member details:", error)
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setEditFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleResetPassword = async () => {
    try {
      const userEmail = localStorage.getItem("shofco_email")
      const response = await sendEmailForMFA(userEmail)
      if (response && response.status === "Success") {
        setShowResetPasswordModal(true)
      } else {
        console.error("Failed to send OTP:", response.message)
      }
    } catch (error) {
      console.error("Error sending OTP:", error)
    }
  }

  const { mutate: mutateNextOfKin, data: nextOfKinData } = useMutation(
    payload => getNextOfKinApi(payload),
    {
      onSettled: () => {
        queryClient.invalidateQueries("get-all-dependents")
      },
    }
  )

  const { mutate: mutateGroupMembers, data: groupMembers } = useMutation(
    payload => getMembersByGroupIdApi(payload),
    {
      onSettled: () => {
        queryClient.invalidateQueries("get-all-dependents")
      },
    }
  )

  useEffect(() => {
    const fetchTotalPremiums = async () => {
      try {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        const fromDate = `01-01-${currentYear}`

        const formattedMonth = String(currentDate.getMonth() + 1).padStart(
          2,
          "0"
        )
        const formattedDay = String(currentDate.getDate()).padStart(2, "0")
        const toDate = `${formattedDay}-${formattedMonth}-${currentYear}`

        const premiumsData = await getTransactionCountsApi({
          fromDate,
          toDate,
          memberId,
          address: {},
        })

        if (premiumsData && premiumsData.data && premiumsData.data.length > 0) {
          const totalPremiumsSum = premiumsData.data.reduce(
            (sum, item) => sum + item.totalPremiums,
            0
          )
          setTotalPremiums(totalPremiumsSum)
        } else {
          console.error(
            "Error: Invalid or empty data array returned for total premiums"
          )
        }
      } catch (error) {
        console.error("Error fetching total premiums:", error)
      }
    }
    fetchTotalPremiums()
  }, [memberId])

  useEffect(() => {
    const fetchClaimsCount = async () => {
      try {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        const fromDate = `01-01-${currentYear}`
        const toDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1
          }-${currentYear}`

        const claimsData = await getClaimStatusCountsApi({
          fromDate,
          toDate,
          memberId,
          address: {},
        })
        if (claimsData && claimsData.data && claimsData.data.length > 0) {
          const sumTotalClaims = claimsData.data.reduce((sum, item) => {
            return sum + item.totalClaims
          }, 0)
          setClaimsCount(sumTotalClaims)
        } else {
          console.error(
            "Error: Invalid or empty data array returned for claims"
          )
        }
      } catch (error) {
        console.error("Error fetching claims count:", error)
      }
    }

    fetchClaimsCount()
  }, [memberId])

  useEffect(() => {
    const fetchDependentsCount = async () => {
      try {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        const fromDate = `01-01-${currentYear}`
        const toDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1
          }-${currentYear}`

        const dependentsData = await getDependentsCountByMemberIdApi({
          fromDate,
          toDate,
          memberId,
          address: {},
        })

        dependentsData?.data && setDependentsData(dependentsData?.data)
        console.log(dependentsData)
      } catch (error) {
        console.error("Error fetching dependents count:", error)
      }
    }

    fetchDependentsCount()
  }, [memberId])

  useEffect(() => {
    const mem = JSON.parse(localStorage.getItem("user"))
    if (!isEmpty(mem)) {
      mutateNextOfKin({ memberId })
      mem?.groupId && mutateGroupMembers({ groupId: mem.groupId })
    }
    setMember(mem)
  }, [])

  useEffect(() => {
    onGetUserProfile()
  }, [onGetUserProfile])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Member Profile" breadcrumbItem="Profile" />
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>We are glad to have you!</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img
                        src={profile}
                        alt=""
                        className="img-fluid"
                        style={{ cursor: "pointer" }}
                      />

                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="44"
                          />
                          <i
                            className="mdi mdi-pencil edit-icon"
                            onClick={handleEditProfilePicture}
                            style={{ cursor: "pointer", marginLeft: "85px", marginBottom: "20px", color: "black" }}
                          />
                        </span>
                      </div>
                      <h5 className="font-size-15 text-truncate">
                        {userProfile.name}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        {userProfile.designation}
                      </p>
                    </Col>
                    <Col sm={8}>
                      <div className="pt-4" style={{ marginTop: "20px" }}>
                        <Button color="primary" onClick={handleResetPassword}>
                          Reset Password{" "}
                          <i className="mdi mdi-arrow-right ms-1" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <CardTitle className="mb-0">Personal Information</CardTitle>
                  </div>
                  <p className="text-muted mb-4">
                    {userProfile.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{`${member?.firstName} ${member?.lastName}`}</td>
                        </tr>
                        <tr>
                          <th scope="row">Phone Number :</th>
                          <td>{member?.mobileNumber1 || "N/A"}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{member?.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">ID Number :</th>
                          <td>{member?.identificationNumber}</td>
                        </tr>
                        <tr>
                          <th scope="row">Member Number :</th>
                          <td> {member?.shofcoId}</td>
                        </tr>
                        <tr>
                          <th scope="row">Ward :</th>
                          <td>{member?.address?.wardName}</td>
                        </tr>
                        <tr>
                          <th scope="row">Status :</th>
                          <td>{member?.status}</td>
                        </tr>
                        <tr>
                          <th scope="row">Policy Maturity :</th>
                          <td>
                            {member?.matured === true ? "Mature" : "Not Mature"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {nextOfKinData && (
                    <>
                      <CardTitle className="mb-4">
                        Next Of Kin Details
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Full Name :</th>
                              <td>
                                {nextOfKinData[0]?.firstName}{" "}
                                {nextOfKinData[0]?.lastName}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Mobile Number :</th>
                              <td>{nextOfKinData[0]?.mobileNumber}</td>
                            </tr>
                            <tr>
                              <th scope="row">Relationship :</th>
                              <td>{nextOfKinData[0]?.relationship}</td>
                            </tr>
                            <tr>
                              <th scope="row">E-mail :</th>
                              <td>{nextOfKinData[0]?.email}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Member Group Details</CardTitle>
                  <Row>
                    <Col md={6}>
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Group Name:</th>
                            <td>{member?.groupName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Group Status:</th>
                            <td>{member?.status}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <CardTitle className="mb-0">Group Members</CardTitle>
                    <Button
                      color="primary"
                      onClick={() => navigate("/contactstransfer")}
                    >
                      Request Group Transfer
                    </Button>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Member Full Names</th>
                          <th>Gender</th>
                          <th>Ward Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupMembers?.members
                          ?.slice(
                            (currentPage - 1) * membersPerPage,
                            currentPage * membersPerPage
                          )
                          ?.map(member => (
                            <tr key={member.memberId}>
                              <td>{`${member.firstName} ${member.lastName}`}</td>
                              <td>{member.gender}</td>
                              <td>
                                {member.address && member.address.wardName}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pagination justify-content-center mt-4">
                    <button
                      className="btn mx-2 border-0 bg-transparent"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      <i className="bx bx-chevron-left" />
                    </button>
                    <span className="mx-2">
                      <span
                        className="bg-primary rounded-circle d-inline-flex justify-content-center align-items-center"
                        style={{ width: "40px", height: "40px" }}
                      >
                        {currentPage}
                      </span>
                    </span>
                    <button
                      className="btn mx-2 border-0 bg-transparent"
                      onClick={nextPage}
                      disabled={
                        groupMembers?.members?.length <=
                        currentPage * membersPerPage
                      }
                    >
                      <i className="bx bx-chevron-right" />
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={showEditInputModal && !selectionMade}
        toggle={() => setShowEditInputModal(!showEditInputModal)}
      >
        <ModalBody>
          <h5>Upload New Profile Image</h5>
          <Dropzone
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          // accept={{ jpg: 'image/jpeg', jpeg: 'image/jpeg', png: 'image/png' }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps({ onChange: handleImageUpload })} />
                <div className="dz-message needsclick">
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showResetPasswordModal}
        toggle={() => setShowResetPasswordModal(!showResetPasswordModal)}
      >
        <ModalHeader
          toggle={() => setShowResetPasswordModal(!showResetPasswordModal)}
        >
          Reset Password
        </ModalHeader>
        <ModalBody>
          <h5 className="mb-4" style={{ textAlign: "center" }}>
            We will send a One Time code to your Email or Mobile Number
          </h5>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <Label className="form-label">Please Enter your Email or Mobile Number</Label>
              <Input
                name="contact"
                className="form-control"
                placeholder="Enter email or mobile number"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.contact || ""}
                invalid={
                  validation.touched.contact && validation.errors.contact
                    ? true
                    : false
                }
              />
              {validation.touched.contact && validation.errors.contact ? (
                <FormFeedback type="invalid">
                  {validation.errors.contact}
                </FormFeedback>
              ) : null}
            </div>
            {codeSent && (
              <>
                <div className="mb-3">
                  <Label className="form-label">New Password</Label>
                  <Input
                    name="newPassword"
                    className="form-control"
                    placeholder="Enter password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.newPassword || ""}
                    invalid={
                      validation.touched.newPassword && validation.errors.newPassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.newPassword &&
                    validation.errors.newPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.newPassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">CODE</Label>
                  <Input
                    name="code"
                    className="form-control"
                    placeholder="Enter code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.code || ""}
                    invalid={
                      validation.touched.code && validation.errors.code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.code && validation.errors.code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.code}
                    </FormFeedback>
                  ) : null}
                </div>
              </>
            )}
            <Row className="mb-3">
              <Col className="text-end">
                <button className="btn btn-primary w-md" type="submit">
                  {codeSent ? "Submit Code" : "Reset"}
                </button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showEditModal}
        toggle={() => setShowEditModal(!showEditModal)}
      >
        <ModalBody>
          <Form onSubmit={handleSubmitEditProfile}>
            <h2>Edit Details</h2>
            <div className="mb-3">
              <Label for="firstName">First Name</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="Enter first name"
                value={editFormData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <Label for="lastName">Last Name</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Enter last name"
                value={editFormData.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <Label for="mobileNumber1">Mobile Number</Label>
              <Input
                type="phone"
                name="mobileNumber1"
                id="mobileNumber1"
                placeholder="Enter mobile number"
                value={editFormData.mobileNumber1}
                onChange={handleInputChange}
              />
            </div>
            <Button color="primary" type="submit" className="w-100">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

const mapStateToProps = ({ Profile }) => ({
  userProfile: Profile.userProfile,
})

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile))
