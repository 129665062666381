import { get, post, put } from './utils';

export async function getMembersByGroupIdApi(data) {
    return get(`/um/group/member/all`, data);
}
export async function getMemberByIdApi(memberId) {
    return get(`/um/member/?memberId=${memberId}`);
}

export async function getDependentsByMemberIdApi(memberId) {
    return get(`/um/member/?memberId=${memberId}`);
}


export async function getMemberByIdNumberApi(idNumber) {
    return get(`/um/member/identification`, { idNumber });
}

export async function addMember(data) {
    return post(`/um/admin/application/register`, data);
}

export async function getNextOfKinApi(data) {
    return get(`/um/nextofkin/`, data);
}

export async function addNextOfKin(data) {
    return post(`/um/nextofkin/save`, data);
}

export async function getNextOfKinByIdApi(data) {
    return get(`/um/nextofkin/id`, data);
}

export async function updateNextOfKinApi(data) {
    return put(`/um/nextofkin/update`, data);
}

export async function updateDependant(data) {
    return put(`/um/dependent/update`, data);
}


export async function getDependantsApi(data) {
    return get(`/um/dependent/`, data);
}

export async function getDependantByIdApi(data) {
    return get(`/um/dependent/id`, data);
}

export async function requestToJoinApi(data) {
    return post(`/public/um/applicant/register`, data);
}

export async function requestTransferApi(data) {
    return post(`/um/member/transfer`, data);

}
export async function saveDependent(data) {
    return post(`/um/dependent/save`, data);
}


const token = localStorage.getItem('sessionToken');
export async function updateProfileAttachmentApi(memberId, formData) {
    if (token) {
        fetch(`https://was.shofcowelfare.org/shofco/api/um/member/profile/update?memberId=${memberId}`,
         {            
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}` 
            },
            body: formData
        }).then(console.log(formData));
    }
}


export async function getNextOfKindetailsByIdApi(nextOfKinId) {
    return get(`/um/nextofkin/id?nextOfKinId=${nextOfKinId}`);
}

export async function getDeceasedDetailsByClaimId(id) {
    return get(`/claim/id?claimId=${id}`);
    
  }

  export async function getAttachementByIdApi(id) {
    return get(`/um/dependent/attachment/?dependentId=${id}`);    
  }


  export async function uploadDepeMedia(entityId, data) {
    let url = `${process.env.REACT_APP_SERVER_URL}/shofco/api/um/dependent/attachment/add?dependentId=${entityId}`;
    const formData = new FormData();
    formData.append('file', data);
    if (token) {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      }).then((res) => res.json());
    }
  }


