import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { useAuth } from "hooks/useAuth"
import { getAllTransactionApi } from "api/transaction"

// Import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Spinners from "components/Common/Spinner"
import { Col, Row, Card, CardBody } from "reactstrap"
import moment from "moment/moment";

function Payments() {
  const { memberId } = useAuth()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true)
  const [groups, setGroups] = useState([])

  
  const { mutate: mutateTransactions } = useMutation(
    () => {
      return getAllTransactionApi({ 
        pageSize: 5, 
        pageNumber: 0, 
        sortBy: "id", 
        searchColumns: [{ name: "memberId", value: memberId }] 
      });
    },
    {
      onSuccess: (data) => {
        setGroups(data.list); 
        setLoading(false); 
      },
      onError: (error) => {
        console.error("Error fetching transactions:", error)
        setLoading(false); 
      }
    }
  );
  
  useEffect(() => {
    mutateTransactions({ pageSize: 5, pageNumber: 0, sortBy: "id", searchColumns: [{ name: "memberId", value: memberId }] });
  }, [memberId]); 


  const columns = useMemo(
    () => [
        {
            Header: "Invoice ID",
            accessor: "invoiceId",
            filterable: true,
        },
      {
        Header: "Transaction ID",
        accessor: "transactionNumber",
        filterable: true,
      },
        {
            Header: 'Transaction Date',
            accessor: 'transactionDateTime',
            filterable: true,
            Cell: (cellProps) => {
                const formattedDate = moment(cellProps.value).format('YYYY MMM DD h:mm A');
                return <span>{formattedDate}</span>;
            }
        },

      {
        Header: "Transaction Status",
        accessor: "status",
        filterable: true,
      },
      {
        Header: "Payment Method",
        accessor: "paymentMode",
        filterable: true,
      },

      {
        Header: "Amount Paid",
        accessor: "amount",
        filterable: true,
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Payments" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      hasCreate
                      isAddOptions={true}
                      columns={columns}
                      data={groups}
                      isGlobalFilter={true}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      addText="Pay Now"
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

Payments.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Payments


