import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, CardBody, Card } from "reactstrap";
import logoImg from "../../assets/images/logo.svg";
import profileImg from "../../assets/images/profile-img.png";
import tickIcon from "../../assets/images/checked.png";


const RegistrationSuccess = () => {
    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} lg={8} xl={6}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="p-4 text-white">
                                                <span className="font-size-14">SHOFCO SUN Welfare</span>
                                                <p>Registration successful.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profileImg} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0 text-center">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logoImg}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <Row className="justify-content-center">
                                            <Col md={10} lg={8} xl={6}>
                                                <div className="text-center">
                                                    <p>We'll process your request and get back to you.</p>
                                                    <img
                                                        src={tickIcon}
                                                        alt="Tick"
                                                        className="img-fluid my-3"
                                                        style={{ height: '50px' }}
                                                    />
                                                </div>
                                                <div className="mt-5 text-center">
                                                    <p>
                                                        Register a different account ?{" "}
                                                        <Link to="/register" className="font-weight-medium text-primary">
                                                            {" "}
                                                            Go back to registration page
                                                        </Link>{" "}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mt-5 text-center">
                                            <p>
                                                © {new Date().getFullYear()} SHOFCO SUN Welfare Program
                                            </p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RegistrationSuccess;









