import React, { useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, FormGroup, FormFeedback, CardTitle, Col, Container, Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from 'moment'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {saveDependent , uploadDepeMedia } from "api/member";
import Swal from 'sweetalert2';

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
const AddDependantsPage = () => {
  const [file, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  const selectOption = [
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
    { value: "Husband", label: "Husband" },
    { value: "Wife", label: "Wife" },
  ];

  const identificationTypeOptions = [
    { value: "National ID", label: "National ID" },
    { value: "Birth Certificate", label: "Birth Certificate" },
  ];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      mobileNumber: "",
      relationship: "",
      birthCertificationNumber: "",
      dob: "",
      identificationType: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      gender: Yup.string().required("Please Select Gender"),
      email: Yup.string().email("Invalid email format").nullable(),
      mobileNumber: Yup.string()
        .matches(/^[0-9]{0,10}$/, "Invalid phone number")
        .nullable(),
      relationship: Yup.string().required("Please Select Relationship"),
      birthCertificationNumber: Yup.string().required(
        "Please Enter Birth Notification No."
      ),
      dob: Yup.string().required("Please Select Date of Birth"),
      identificationType: Yup.string().required("Please Select Identification Type"),
    }),
    onSubmit: async ({ dob, ...values }) => {
      const data = {
        ...values,
        memberId: localStorage.getItem('memberId'),
        dob: moment(dob).format('MM-DD-YYYY')
      };
      Swal.fire({
        title: 'Adding Dependent...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      try {
        const response = await saveDependent(data);
        if (response.status === 'Success') {
          const depId = response?.data?.id;
          await uploadDepeMedia(depId, file);
          Swal.fire('Dependent added successfully', 'success');

          navigate('/dependants');
        } else {
          // Handle error
          Swal.fire('Failed to add dependent', 'error');

          console.error("Failed to save dependent:", response);
        }
      } catch (error) {
        // Handle error
        Swal.fire('Failed to add dependent', 'error');
        console.error("Error:", error);
      }
    },
  });


  const handleInputChange = e => {
    const { name, value } = e.target
    validation.setFieldValue(name, value)
  }

  const handleDobChange = e => {
    const { name, value } = e.target
    validation.setFieldValue(name, value)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dependants" breadcrumbItem="Add Dependant" />
          <Row>
            <Col xs="12">
              <Card>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <CardTitle className="mb-0">Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all Dependants Details
                    </p>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Link to="/dependants">
                      <Button type="button" color="primary" className="btn">
                        <i className="bx bx-arrow-back"></i> Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <CardBody>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">First Name</Label>
                          <Input
                            id="productname"
                            name="firstName"
                            type="text"
                            className={`form-control ${validation.touched.firstName &&
                              validation.errors.firstName
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="First Name"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName}
                          />
                          {validation.touched.firstName &&
                            validation.errors.firstName && (
                              <div className="invalid-feedback">
                                {validation.errors.firstName}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="lastName">Last Name</Label>
                          <Input
                            id="lastName"
                            name="lastName"
                            type="text"
                            className={`form-control ${validation.touched.lastName &&
                              validation.errors.lastName
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Last Name"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName}
                          />
                          {validation.touched.lastName &&
                            validation.errors.lastName && (
                              <div className="invalid-feedback">
                                {validation.errors.lastName}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <Label for={`relationship`}>Relationship</Label>
                          <Input
                            className="form-select"
                            id={`relationship`}
                            type='select'
                            name='relationship'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.relationship || ""}
                            invalid={validation.touched.relationship && validation.errors.relationship ? true : false}
                          >
                            <option defaultValue value={''}>
                              Select Relationship...
                            </option>
                            {selectOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.relationship && validation.errors.relationship ? (
                            <FormFeedback type="invalid">{validation.errors.relationship}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label for={`gender`}>Sex</Label>
                          <Input
                            className="form-select"
                            id={`gender`}
                            type='select'
                            name='gender'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.gender || ""}
                            invalid={
                              validation.touched.gender && validation.errors.gender ? true : false
                            }
                          >
                            <option defaultValue>
                              Select Gender...
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Input>
                          {validation.touched.gender && validation.errors.gender ? (
                            <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="email">Email (Optional) </Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            className={`form-control ${validation.touched.email &&
                              validation.errors.email
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Email"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <div className="invalid-feedback">
                                {validation.errors.email}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="mobileNumber">Mobile Number (Optional) </Label>
                          <Input
                            id="mobileNumber"
                            name="mobileNumber"
                            type="tel"
                            className={`form-control ${validation.touched.mobileNumber &&
                              validation.errors.mobileNumber
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Mobile Number"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobileNumber}
                            maxLength={10}
                          />
                          {validation.touched.mobileNumber &&
                            validation.errors.mobileNumber && (
                              <div className="invalid-feedback">
                                {validation.errors.mobileNumber}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="dob">Date of Birth</Label>
                          <Input
                            id="dob"
                            name="dob"
                            type="date"
                            className={`form-control ${validation.touched.dob && validation.errors.dob
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Select Date of Birth"
                            onChange={handleDobChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dob}
                          />
                          {validation.touched.dob && validation.errors.dob && (
                            <div className="invalid-feedback">
                              {validation.errors.dob}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="identificationType">Identification Type</Label>
                          <Select
                            className="select2"
                            placeholder="Select"
                            options={identificationTypeOptions}
                            onChange={(option) =>
                              validation.setFieldValue("identificationType", option.value)
                            }
                            onBlur={() => validation.setFieldTouched("identificationType", true)}
                            value={identificationTypeOptions.find(
                              (option) =>
                                option.value === validation.values.identificationType
                            )}
                          />
                          {validation.touched.identificationType &&
                            validation.errors.identificationType && (
                              <div className="invalid-feedback">
                                {validation.errors.identificationType}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="birthCertificationNumber">
                            Birth Certificate Number
                          </Label>
                          <Input
                            id="birthCertificationNumber"
                            name="birthCertificationNumber"
                            type="text"
                            className={`form-control ${validation.touched.birthCertificationNumber &&
                              validation.errors.birthCertificationNumber
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Birth Certification Number"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.birthCertificationNumber}
                            maxLength={10}
                          />
                          {validation.touched.birthCertificationNumber &&
                            validation.errors.birthCertificationNumber && (
                              <div className="invalid-feedback">
                                {validation.errors.birthCertificationNumber}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="birthCert">
                            Upload Dependant Birth Certificate
                          </Label>
                          <Input
                            name="birthCert"
                            type="file"
                            className="form-control"
                            id="birthCert"
                            placeholder="Enter Dependant Birth Certificate"
                            onChange={(e) => { handleFileChange(e); }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                        color="primary"
                      >
                        Submit
                      </Button>
                      <Button
                        type="reset"
                        className="btn btn-secondary waves-effect waves-light"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddDependantsPage;