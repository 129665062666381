import { post, get } from './utils';


export async function getClaimAttachmentsByIdApi(id){
  console.log(id);
  return get(`/claim/attachment/?claimId=${id}`);

}

export async function getAllClaimApi(data) {
  return post('/claim/search', data)
}

export async function getClaimByIdApi(claimId) {
  return get(`/claim/all/${claimId}`);
}


export async function getDependentsByIdApi(data) {
  return get(`/um/dependent/`, data);
}

export async function getNextOfKinByIdApi(data) {
  return get(`/um/nextofkin/`, data);
}

// export async function uploadMedia(entityId, data) {
//   let url = `${process.env.REACT_APP_SERVER_URL}/shofco/api/claim/attachment/add?claimId=${entityId}`;
//   const formData = new FormData();
//   formData.append('file', data);
//   const token = localStorage.getItem('sessionToken');
//   if (token) {
//     return fetch(url, {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//       },
//       body: formData,
//     }).then((res) => res.json());
//   }
// }



export async function getClaimDetailsByIdApi(data) {
  return get(`/claim/id`, data);
}

