// import React, { useState, useEffect } from "react"
// import { Link, useNavigate } from "react-router-dom";

// import {
//   Button,
//   Card,
//   CardBody,
//   CardTitle,
//   Col,
//   Container,
//   Form,
//   Input,
//   Label,
//   Row,
// } from "reactstrap"
// import moment from 'moment'
// import Select from "react-select"
// import * as Yup from "yup"
// import { useFormik } from "formik"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { useMutation, useQueryClient } from "react-query"
// import { addNextOfKin, uploadDepeMedia} from "api/member"


// const formatBytes = (bytes, decimals = 2) => {
//   if (bytes === 0) return "0 Bytes"

//   const k = 1024
//   const dm = decimals < 0 ? 0 : decimals
//   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

//   const i = Math.floor(Math.log(bytes) / Math.log(k))

//   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
// }

// const NextOfKinData = () => {
//   const queryClient = useQueryClient()
//   const [selectedFiles, setselectedFiles] = useState([])
//   const [file, setSelectedFile] = useState(null);
//   const [authUser, setAuthUser] = useState(null)
//   const [authToken, setAuthToken] = useState(null)
//   const [apiResponse, setApiResponse] = useState(null)
//   const navigate = useNavigate();

//   const selectOption = [
//     { value: "Son", label: "Son" },
//     { value: "Daughter", label: "Daughter" },
//     { value: "Husband", label: "Husband" },
//     { value: "Wife", label: "Wife" },
//     { value: "other", label: "Other" },
//   ]

//   const validation = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       memberId: "memberId",
//       firstName: "",
//       lastName: "",
//       gender: "",
//       email: "",
//       mobileNumber: "",
//       relationship: "",
//       nationalIdentification: "",
//       dob: "",
//     },
//     validationSchema: Yup.object({
//       firstName: Yup.string().required("Please Enter First Name"),
//       lastName: Yup.string().required("Please Enter Last Name"),
//       gender: Yup.string().required("Please Select Gender"),
//       mobileNumber: Yup.string().required("Please Enter Phone Number"),
//       relationship: Yup.string().required("Please Select Relationship"),
//       nationalIdentification: Yup.string().required(
//         "Please Enter National Identification Number"
//       ),
//       dob: Yup.string().required("Please Select DOB"),
//     }),
//     onSubmit: async ({ dob, ...values }) => {
//       const data = {
//         ...values,
//         memberId: localStorage.getItem('memberId'),
//         // memberId: member?.memberId,
//         dob: moment(dob).format('MM-DD-YYYY')
//       };

//       try {
//         const response = await addNextOfKin(data);
//         if (response.status === 'Success') {
//           const depId = response?.data?.id;
//           await uploadDepeMedia(depId, file);
//           setVisible(false);
//         } else {
//           // Handle error
//           console.error("Failed to save dependent:", response);
//         }
//       } catch (error) {
//         // Handle error
//         console.error("Error:", error);
//       }
//     },
//   });

//   const handleInputChange = e => {
//     const { name, value } = e.target
//     validation.setFieldValue(name, value)
//   }

//   const handleDobChange = e => {
//     const { name, value } = e.target
//     validation.setFieldValue(name, value)
//   }
//   const truncatedMobileNumber = validation.values.mobileNumber.slice(0, 10)

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setSelectedFile(file);
//   };

//   const { mutate: mutateAddNextOfKin } = useMutation(addNextOfKin, {
//     onSuccess: data => {
//       console.log("Next of Kin added successfully!", data)
//       queryClient.invalidateQueries("nextOfKinList")
//       validation.resetForm()
//       setselectedFiles([])
//       navigate('/nextofkin');
//     },
//     onError: error => {
//       console.error("Failed to add Next of Kin", error)
//     },
//   })

//   const handleSubmit = async formData => {
//     try {
//       const memberId = localStorage.getItem("memberId")

//       if (!memberId) {
//         console.error("Member ID not found in local storage")
//         return
//       }
//       const fileFormData = new FormData()
//       fileFormData.append("file", selectedFiles[0])
//       const fileUploadResponse = await uploadDepeMedia(
//         fileFormData
//       )

//       if (fileUploadResponse.status === 200) {
//         const fileUrl = fileUploadResponse.data.url
//         formData.birthCertificateUrl = fileUrl

//         formData.memberId = memberId
//         mutateAddNextOfKin(formData)
//       } else {
//         console.error("Failed to upload the file")
//       }
//     } catch (error) {
//       console.error("Error updating Next of Kin:", error)
//     }
//   }

//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem("user"))
//     if (user) {
//       setAuthUser(user)
//       setAuthToken(user.authToken)
//     }
//   }, [])

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Breadcrumbs title="Home" breadcrumbItem="Next of KIN Details" />
//           <Row>
//             <Col xs="12">
//               <Card>
//                 <CardBody>
//                   <Row className="align-items-center">
//                     <Col xs="auto">
//                       <CardTitle className="mb-0">Basic Information</CardTitle>
//                       <p className="card-title-desc mb-4">
//                         Fill all Next of KIN Details
//                       </p>
//                     </Col>
//                     <Col className="d-flex justify-content-end">
//                       <Link to="/nextofkin">
//                         <Button type="button" color="primary" className="btn">
//                           <i className="bx bx-arrow-back"></i> Back
//                         </Button>
//                       </Link>
//                     </Col>
//                   </Row>
//                   <Form onSubmit={validation.handleSubmit}>
//                     <Row>
//                       <Col sm="6">
//                         <div className="mb-3">
//                           <Label htmlFor="firstName">First Name</Label>
//                           <Input
//                             id="firstName"
//                             name="firstName"
//                             type="text"
//                             className="form-control"
//                             placeholder="First Name"
//                             onChange={handleInputChange}
//                             onBlur={validation.handleBlur}
//                             value={validation.values.firstName}
//                           />
//                         </div>
//                       </Col>
//                       <Col sm="6">
//                         <div className="mb-3">
//                           <Label htmlFor="lastName">Last Name</Label>
//                           <Input
//                             id="lastName"
//                             name="lastName"
//                             type="text"
//                             className={`form-control ${validation.touched.lastName &&
//                                 validation.errors.lastName
//                                 ? "is-invalid"
//                                 : ""
//                               }`}
//                             placeholder="Last Name"
//                             onChange={handleInputChange}
//                             onBlur={validation.handleBlur}
//                             value={validation.values.lastName}
//                           />
//                         </div>
//                       </Col>
//                       <Col sm="6">
//                         <div className="mb-3">
//                           <Label htmlFor="relationship">Relationship</Label>
//                           <Select
//                             className="select2"
//                             placeholder="Select"
//                             options={selectOption}
//                             onChange={option =>
//                               validation.setFieldValue(
//                                 "relationship",
//                                 option.value
//                               )
//                             }
//                             onBlur={() =>
//                               validation.setFieldTouched("relationship", true)
//                             }
//                             value={selectOption.find(
//                               option =>
//                                 option.value === validation.values.relationship
//                             )}
//                           />
//                         </div>
//                       </Col>
//                       <Col sm="6">
//                         <div className="mb-3">
//                           <Label htmlFor="mobileNumber">Mobile Number</Label>
//                           <Input
//                             id="mobileNumber"
//                             name="mobileNumber"
//                             type="tel"
//                             className={`form-control ${validation.touched.mobileNumber &&
//                                 validation.errors.mobileNumber
//                                 ? "is-invalid"
//                                 : ""
//                               }`}
//                             placeholder="Mobile Number"
//                             onChange={handleInputChange}
//                             onBlur={validation.handleBlur}
//                             value={truncatedMobileNumber}
//                           />
//                         </div>
//                       </Col>
//                       <Col sm="6">
//                         <div className="mb-3">
//                           <Label htmlFor="email">Email (optional)</Label>
//                           <Input
//                             id="email"
//                             name="email"
//                             type="email"
//                             className={`form-control ${validation.touched.email &&
//                                 validation.errors.email
//                                 ? "is-invalid"
//                                 : ""
//                               }`}
//                             placeholder="Email"
//                             onChange={handleInputChange}
//                             onBlur={validation.handleBlur}
//                             value={validation.values.email}
//                           />
//                         </div>
//                       </Col>
//                       <Col sm="6">
//                         <div className="mb-3">
//                           <Label htmlFor="gender">Gender</Label>
//                           <Select
//                             options={[
//                               { value: "male", label: "Male" },
//                               { value: "female", label: "Female" },
//                               { value: "other", label: "Other" },
//                             ]}
//                             onChange={option =>
//                               validation.setFieldValue("gender", option.value)
//                             }
//                             onBlur={() =>
//                               validation.setFieldTouched("gender", true)
//                             }
//                             value={selectOption.find(
//                               option =>
//                                 option.value === validation.values.gender
//                             )}
//                           />
//                         </div>
//                       </Col>
//                       <Col sm="6">
//                         <div className="mb-3">
//                           <Label htmlFor="nationalIdentification">
//                             National Identification Number
//                           </Label>
//                           <Input
//                             id="nationalIdentification"
//                             name="nationalIdentification"
//                             type="text"
//                             className={`form-control ${validation.touched.nationalIdentification &&
//                                 validation.errors.nationalIdentification
//                                 ? "is-invalid"
//                                 : ""
//                               }`}
//                             placeholder="National Identification Document"
//                             onChange={handleInputChange}
//                             onBlur={validation.handleBlur}
//                             value={validation.values.nationalIdentification}
//                             maxLength={10}
//                           />
//                           {validation.touched.nationalIdentification &&
//                             validation.errors.nationalIdentification && (
//                               <div className="invalid-feedback">
//                                 {validation.errors.nationalIdentification}
//                               </div>
//                             )}
//                         </div>
//                       </Col>
//                       <Col lg="6">
//                         <div className="mb-3">
//                           <Label htmlFor="dob">Date of Birth</Label>
//                           <Input
//                             id="dob"
//                             name="dob"
//                             type="date"
//                             className={`form-control ${validation.touched.dob && validation.errors.dob
//                                 ? "is-invalid"
//                                 : ""
//                               }`}
//                             placeholder="Select Date of Birth"
//                             onChange={handleDobChange}
//                             onBlur={validation.handleBlur}
//                             value={validation.values.dob}
//                           />
//                         </div>
//                       </Col>
//                       <Col lg="6">
//                         <div className="mb-3">
//                           <Label for="birthCert">
//                             Upload Next of KIN Identification Document
//                           </Label>
//                           <Input
//                             name="birthCert"
//                             type="file"
//                             className="form-control"
//                             id="birthCert"
//                             placeholder="Upload Next of KIN Identification Document"
//                             onChange={(e) => { handleFileChange(e); }}
//                           />
//                         </div>
//                       </Col>
//                     </Row>
//                     <div className="d-flex flex-wrap gap-2">
//                       <Button
//                         type="submit"
//                         className="btn btn-primary waves-effect waves-light"
//                         color="primary"
//                       >
//                         Submit
//                       </Button>
//                       <Button
//                         type="reset"
//                         className="btn btn-secondary waves-effect waves-light"
//                         color="secondary"
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </Form>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   )
// }

// export default NextOfKinData



import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import moment from 'moment'
import Select from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useMutation, useQueryClient } from "react-query"
import { addNextOfKin, uploadDepeMedia} from "api/member"


const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const NextOfKinData = () => {
  const queryClient = useQueryClient()
  const [selectedFiles, setselectedFiles] = useState([])
  const [file, setSelectedFile] = useState(null);
  const [authUser, setAuthUser] = useState(null)
  const [authToken, setAuthToken] = useState(null)
  const [apiResponse, setApiResponse] = useState(null)
  const navigate = useNavigate();

  const selectOption = [
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
    { value: "Husband", label: "Husband" },
    { value: "Wife", label: "Wife" },
    { value: "other", label: "Other" },
  ]

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      mobileNumber: "",
      relationship: "",
      nationalIdentification: "",
      dob: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      gender: Yup.string().required("Please Select Gender"),
      mobileNumber: Yup.string().required("Please Enter Phone Number"),
      relationship: Yup.string().required("Please Select Relationship"),
      nationalIdentification: Yup.string().required(
        "Please Enter National Identification Number"
      ),
      dob: Yup.string().required("Please Select DOB"),
    }),
    onSubmit: async ({ dob, ...values }) => {
      const data = {
        ...values,
        memberId: localStorage.getItem('memberId'),
        dob: moment(dob).format('MM-DD-YYYY')
      };

      try {
        const response = await addNextOfKin(data);
        if (response.status === 'Success') {
          const depId = response?.data?.id;
          await uploadDepeMedia(depId, file);
          navigate('/nextofkin');
          setVisible(false);
        } else {
          // Handle error
          console.error("Failed to save dependent:", response);
        }
      } catch (error) {
        // Handle error
        console.error("Error:", error);
      }
    },
  });
  const handleInputChange = e => {
    const { name, value } = e.target;
    validation.setFieldValue(name, value);
  };

  const handleDobChange = e => {
    const { name, value } = e.target;
    validation.setFieldValue(name, value);
  };

  const truncatedMobileNumber = validation.values.mobileNumber.slice(0, 10)

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Next of KIN Details" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <CardTitle className="mb-0">Basic Information</CardTitle>
                      <p className="card-title-desc mb-4">
                        Fill all Next of KIN Details
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Link to="/nextofkin">
                        <Button type="button" color="primary" className="btn">
                          <i className="bx bx-arrow-back"></i> Back
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="firstName">First Name</Label>
                          <Input
                            id="firstName"
                            name="firstName"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="lastName">Last Name</Label>
                          <Input
                            id="lastName"
                            name="lastName"
                            type="text"
                            className={`form-control ${validation.touched.lastName &&
                                validation.errors.lastName
                                ? "is-invalid"
                                : ""
                              }`}
                            placeholder="Last Name"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="relationship">Relationship</Label>
                          <Select
                            className="select2"
                            placeholder="Select"
                            options={selectOption}
                            onChange={option =>
                              validation.setFieldValue(
                                "relationship",
                                option.value
                              )
                            }
                            onBlur={() =>
                              validation.setFieldTouched("relationship", true)
                            }
                            value={selectOption.find(
                              option =>
                                option.value === validation.values.relationship
                            )}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="mobileNumber">Mobile Number</Label>
                          <Input
                            id="mobileNumber"
                            name="mobileNumber"
                            type="tel"
                            className={`form-control ${validation.touched.mobileNumber &&
                                validation.errors.mobileNumber
                                ? "is-invalid"
                                : ""
                              }`}
                            placeholder="Mobile Number"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={truncatedMobileNumber}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="email">Email (optional)</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            className={`form-control ${validation.touched.email &&
                                validation.errors.email
                                ? "is-invalid"
                                : ""
                              }`}
                            placeholder="Email"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="gender">Gender</Label>
                          <Select
                            options={[
                              { value: "male", label: "Male" },
                              { value: "female", label: "Female" },
                              { value: "other", label: "Other" },
                            ]}
                            onChange={option =>
                              validation.setFieldValue("gender", option.value)
                            }
                            onBlur={() =>
                              validation.setFieldTouched("gender", true)
                            }
                            value={selectOption.find(
                              option =>
                                option.value === validation.values.gender
                            )}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="nationalIdentification">
                            National Identification Number
                          </Label>
                          <Input
                            id="nationalIdentification"
                            name="nationalIdentification"
                            type="text"
                            className={`form-control ${validation.touched.nationalIdentification &&
                                validation.errors.nationalIdentification
                                ? "is-invalid"
                                : ""
                              }`}
                            placeholder="National Identification Document"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nationalIdentification}
                            maxLength={10}
                          />
                          {validation.touched.nationalIdentification &&
                            validation.errors.nationalIdentification && (
                              <div className="invalid-feedback">
                                {validation.errors.nationalIdentification}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="dob">Date of Birth</Label>
                          <Input
                            id="dob"
                            name="dob"
                            type="date"
                            className={`form-control ${validation.touched.dob && validation.errors.dob
                                ? "is-invalid"
                                : ""
                              }`}
                            placeholder="Select Date of Birth"
                            onChange={handleDobChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dob}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="birthCert">
                            Upload Next of KIN Identification Document
                          </Label>
                          <Input
                            name="birthCert"
                            type="file"
                            className="form-control"
                            id="birthCert"
                            placeholder="Upload Next of KIN Identification Document"
                            onChange={(e) => { handleFileChange(e); }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                        color="primary"
                      >
                        Submit
                      </Button>
                      <Button
                        type="reset"
                        className="btn btn-secondary waves-effect waves-light"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NextOfKinData














