import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllRelationshipsApi } from 'api/report';
import { Button, Card, CardTitle, CardBody, Col, Form, Input, Label, Row, FormGroup, FormFeedback
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getDependantByIdApi, updateDependant } from "api/member"

const EditDependant = ({ history , attachmentData }) => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate();

  const {
    mutate: mutateDependant,
    isLoading: isLoadingRegions,
    data: dependantData,
  } = useMutation(payload => getDependantByIdApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("/um/dependent/id")
    },
  })

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [file, setSelectedFile] = useState(null);

  useEffect(() => {
    mutateDependant({
      dependentId: id,
    })
  }, [id]);

  const existingFile = dependantData?.file;

  const { mutate: mutateRelationships,
    isLoading: isLoadingRelationships,
    data: relationshipsData, } = useMutation(
      (payload) => getAllRelationshipsApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('/public/um/setup/relationship');
        },
      }
    );
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
    };
  
  useEffect(() => {
    mutateRelationships({
    });
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...dependantData
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      relationship: Yup.string().required("Relationship is required"),
      dob: Yup.date().required("Date of Birth is required"),
      gender: Yup.string().required("Gender is required"),
      status: Yup.string().required("Status is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      mobileNumber: Yup.string().required("Phone Number is required"),
      birthCertificationNumber: Yup.string().required(
        "Birth Cert No is required"
      ),
    }),
    onSubmit: async values => {
      console.log(values)
      saveDependantData(values)
    }

  })

  const saveDependantData = async (formData) => {
    try {
      const updatedData = await updateDependant(formData);
      console.log("Next of Kin updated successfully!", updatedData)
      validation.resetForm()
      navigate("/dependants")      

    } catch (error) {
      console.error("Error editing Next of Kin:", error)
    }
    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Dependents" breadcrumbItem="Edit Dependent" />
        <Form autoComplete="off" onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Edit Dependent Information</CardTitle>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label for="firstName">
                          First Name
                        </Label>
                        <Input
                          name="firstName"
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="Enter First Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName}
                          invalid={
                            validation.touched.firstName && validation.errors.firstName ? true : false
                          }
                        />
                        {validation.touched.firstName && validation.errors.firstName ? (
                          <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                        ) : null}
                      </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="lastName">Last Name</Label>
                          <Input
                            id="lastName"
                            name="lastName"
                            type="text"
                            value={validation.values.lastName}
                            className="form-control"
                            placeholder="Last Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.lastName &&
                            validation.errors.lastName && (
                              <div className="text-danger">
                                {validation.errors.lastName}
                              </div>
                            )}
                        </FormGroup>
                      <FormGroup className="mb-3">
                        <Label>Gender</Label>
                        <Input type='select'
                          className="form-select"
                          name="gender"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gender || ""}
                          invalid={
                            validation.touched.gender && validation.errors.gender ? true : false
                          }
                        >
                          <option defaultValue>
                            Select Gender...
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Input>
                        {validation.touched.gender && validation.errors.gender ? (
                          <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                        ) : null}
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label htmlFor="birthCertificationNumber">
                          Birth Certificate Number
                        </Label>
                        <Input
                          id="birthCertificationNumber"
                          name="birthCertificationNumber"
                          type="text"
                          className="form-control"
                          placeholder="Birth Cert No"
                          maxLength={10}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.birthCertificationNumber || ''}
                        />
                        {validation.touched.birthCertificationNumber &&
                          validation.errors.birthCertificationNumber && (
                            <div>
                              {validation.errors.birthCertificationNumber}
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="mb-3">
                        <FormGroup className="mb-3">
                          <Label>Relationship</Label>
                          <Input className="form-select"
                            name="relationship"
                            type='select'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.relationship || ""}
                            invalid={
                              validation.touched.relationship && validation.errors.relationship ? true : false
                            }
                          >
                            <option defaultValue value={''}>
                              Select Relationship...
                            </option>
                            {
                              relationshipsData && relationshipsData.map(item => (
                                <option key={item.id} value={item.name}>
                                  {item.name}
                                </option>
                              ))
                            }
                          </Input>
                          {validation.touched.relationship && validation.errors.relationship ? (
                            <FormFeedback type="invalid">{validation.errors.relationship}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label for="basicpill-pancard-input5">
                          Date Of birth
                        </Label>
                        <Input
                          name="dob"
                          type="date"
                          className="form-control"
                          id="basicpill-pancard-input5"
                          placeholder="Select Date of Birth"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.dob || ""}
                          invalid={
                            validation.touched.dob && validation.errors.dob ? true : false
                          }
                        />
                        {validation.touched.dob && validation.errors.dob ? (
                          <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                        ) : null}
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label htmlFor="mobileNumber">Phone Number</Label>
                        <Input
                          id="mobileNumber"
                          name="mobileNumber"
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          maxLength={10}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobileNumber}
                        />
                        {validation.touched.mobileNumber &&
                          validation.errors.mobileNumber && (
                            <div>{validation.errors.mobileNumber}</div>
                          )}
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email}
                        />
                        {validation.touched.email && validation.errors.email && (
                          <div>{validation.errors.email}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      {existingFile ? (
                        <div>
                          <p>File: {existingFile.name}</p>
                        </div>
                      ) : (
                        <FormGroup className="mb-3">
                          <Label for="birthCert">Upload Dependant Certificate of Birth</Label>
                          <Input
                            type="file"
                            name="birthCert"
                            className="form-control"
                            id="birthCert"
                            placeholder="Enter Dependant Birth Certificate"
                            onChange={(e) => handleFileChange(e)}
                          />
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn"
                    >
                      Save Changes
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default EditDependant 