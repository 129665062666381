import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap"

import SplineArea from "./SplineArea"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { useAuth } from "hooks/useAuth"
import { getClaimStatusCountsApi, getDependentsCountByMemberIdApi, getTransactionCountsApi } from "api/report"

export const sumArray = (arr, key) => {
  return (arr && arr.length) ? arr.reduce((a, b) => a + (b[key] || 0), 0) : 0;
};

const Dashboard = props => {
  document.title = "Dashboard | SUN Welfare Member Portal"

  const { memberId } = useAuth()
  const navigate = useNavigate();
  const [dependentsCount, setDependentsCount] = useState(0);
  const [claimsCount, setClaimsCount] = useState(0);
  const [totalPremiums, setTotalPremiums] = useState(0);



  useEffect(() => {
    const fetchClaimsCount = async () => {
      try {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const fromDate = `01-01-${currentYear}`;
        const toDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentYear}`;

        const claimsData = await getClaimStatusCountsApi({
          fromDate,
          toDate,
          memberId,
          address: {},
        });

        setClaimsCount(claimsData?.data);

      } catch (error) {
        console.error("Error fetching claims count:", error);
      }
    };

    fetchClaimsCount();
  }, [memberId]);


  useEffect(() => {
    const fetchDependentsCount = async () => {
      try {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const fromDate = `01-01-${currentYear}`;
        const toDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentYear}`;

        const dependentsData = await getDependentsCountByMemberIdApi({
          fromDate,
          toDate,
          memberId,
          address: {},
        });
        setDependentsCount(dependentsData?.data);
      } catch (error) {
        console.error("Error fetching dependents count:", error);
      }
    };

    fetchDependentsCount();
  }, [memberId]);


  useEffect(() => {
    const fetchTotalPremiums = async () => {
      try {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const fromDate = `01-01-${currentYear}`;

        const formattedMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
        const formattedDay = String(currentDate.getDate()).padStart(2, "0");
        const toDate = `${formattedDay}-${formattedMonth}-${currentYear}`;

        const premiumsData = await getTransactionCountsApi({
          fromDate,
          toDate,
          memberId,
          address: {},
        });

        setTotalPremiums(premiumsData?.data);

      } catch (error) {
        console.error("Error fetching total premiums:", error);
      }
    };

    fetchTotalPremiums();
  }, [memberId]);


  const handleAddDependantClick = () => {
    navigate('/dependants/create');
  };

  const handleClaimsClick = () => {
    navigate('/claims');
  };

  const handlePayPremiumClick = () => {
    navigate('/addpayment');
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col md={7}>
                  <Breadcrumbs
                    header={props.t("Member Dashboard")}
                    title={props.t("Home")}
                    breadcrumbItem={props.t("Member Dashboard")}
                  />
                </Col>
                <Col md={5}>
                  <Row className="align-items-center justify-content-between">
                    <Col>
                      <Button
                        type="submit"
                        color="primary"
                        outline
                        className="font-size-10"
                        onClick={handleAddDependantClick}
                      >
                        Add Dependant
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="submit"
                        color="primary"
                        outline
                        className="font-size-10"
                        onClick={handlePayPremiumClick}
                      >
                        Pay Premium
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="submit"
                        color="primary"
                        outline
                        className="font-size-10"
                        onClick={handleClaimsClick}
                      >
                        View Claims
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div>
                    <p className="text-bold b-1">Premium Payments</p>
                    <p className="text-muted font-size-11 mb-1">
                      Individual Welfare Contributions
                    </p>
                  </div>
                  <Row className="py-1">
                    <Col >
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="mt-3">
                            <div className="d-flex">
                              <i className="bx bx-group" style={{ fontSize: '30px', paddingBottom: "7px" }}></i>
                            </div>
                            <div className="d-flex">
                              <h2>{dependentsCount.length ? sumArray(dependentsCount, 'totalDependents') : 0}</h2>
                            </div>
                            <p className="text-muted mb-1">Dependants</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="mt-3">
                            <div className="d-flex">
                              <i className="bx bx-credit-card" style={{ fontSize: '30px', paddingBottom: "7px" }}></i>
                            </div>
                            <div className="d-flex">
                              <h2>{totalPremiums.length ? sumArray(totalPremiums, 'totalPremiums') : 0}</h2>
                            </div>
                            <p className="text-muted mb-1">Payments to Date</p>
                            {/*<div style={{ display: 'inline-block', background: '#b9ecdc', borderRadius: '6px', padding: '5px' }}>*/}
                            {/*  <span className="d-flex text-success font-size-10">*/}
                            {/*    <i className="mdi mdi-arrow-up ms-1"></i>*/}
                            {/*    <p style={{ margin: '0' }}>2.1%</p>*/}
                            {/*  </span>*/}
                            {/*</div>*/}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="mt-3">
                            <div className="d-flex">
                              <i className="bx bx-file" style={{ fontSize: '30px', paddingBottom: "7px" }}></i>
                            </div>
                            <div className="d-flex">
                              <h2 className="d-flex font-size-24 align-items-center">
                                {claimsCount.length ? sumArray(claimsCount, 'totalClaims') : 0}{" "}
                              </h2>
                            </div>
                            <p className="text-muted mb-1">
                              Claims To Date
                            </p>
                            {/*<div style={{ display: 'inline-block', background: '#dad0d4', borderRadius: '6px', padding: '5px' }}>*/}
                            {/*  <span className="d-flex text-danger font-size-10">*/}
                            {/*    <i className="mdi mdi-arrow-up ms-1"></i>*/}
                            {/*    <p style={{ margin: '0' }}>0.47%</p>*/}
                            {/*  </span>*/}
                            {/*</div>*/}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <div>
                    <p className="text-bold b-1">Premium Payments</p>
                    <p className="text-muted font-size-11 mb-1">
                      Individual Welfare Contributions
                    </p>
                  </div>
                  <Row className="py-1">
                    <Col >
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="mt-3">
                            <div className="d-flex">
                              <p className="text-muted mb-1">Kes.</p>
                              <h2>{totalPremiums.length ? sumArray(totalPremiums, 'totalPremiums') : 0}</h2>
                            </div>
                            <p className="text-muted mb-1">Payment To Date</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="mt-3">
                            <div className="d-flex">
                              <p className="text-muted mb-1">Kes.</p>
                              <h2>100</h2>
                            </div>
                            <p className="text-muted mb-1">Monthly Payment</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}></Col>
              </Row>
              <SplineArea dataColors='["--bs-primary", "--bs-success", "--bs-danger"]' claims={claimsCount || []} premiums={totalPremiums || []} members={dependentsCount || []} />
            </CardBody>
          </Card>
        </Row>
      </Container>
    </div>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
