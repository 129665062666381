import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { useAuth } from 'hooks/useAuth';

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const { user, logout } = useAuth();
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    logout();
    // dispatch(logoutUser(history));
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);