import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import { useTranslation } from 'react-i18next';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";


import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { useMutation, useQueryClient } from 'react-query';
import { getAllInvoicesApi } from 'api/transaction';
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import moment from "moment";

function Invoice() {

  const { memberId } = useAuth();
  const queryClient = useQueryClient();
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();


  const handleViewDetails =(record) =>{
    console.log(record)
    navigate(`/invoices/${record.original.id}`);
  }

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    searchColumns: []
  });

  const { mutate: mutateInvoices,
    isLoading: isLoadingInvoices,
    data: invoicesData, } = useMutation(
      (payload) => getAllInvoicesApi(payload),
      {
        onSuccess: res => {
          res.pages > 0 && setPages(res.pages);
          console.log(res)
        },
        onSettled: () => {
          setLoading(false);
          queryClient.invalidateQueries('get-invoices');
        },
      }
    );

    useEffect(() => {
      mutateInvoices({ memberId });
    }, []);

    const columns = useMemo(
      () => [
        {
          Header: 'Invoice Number',
          accessor: 'invoiceNumber',
        },

        {
          Header: 'Invoice Date',
          accessor: 'createdDate',
          filterable: true,
          Cell: (cellProps) => {
            const formattedDate = moment(cellProps.value).format('YYYY MMM DD h:mm A');
            return <span>{formattedDate}</span>;
          }
        },
        {
          Header: 'Amount Payable',
          accessor: 'totalAmount',
        },
        {
          Header: 'Paid Amount',
          accessor: 'paidAmount',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
      ],
      [invoicesData] 
    );
  

  //meta title
  document.title = "Invoices | SUN Welfare Member System";

  const onChangePagination = (
    { page, limit }, filters, sorter) => {

    console.log(filters)
    let searchColumns = isEmpty(filters) ? [] : [
      filters?.id && {
        id: filters?.id
      },
      filters?.applicationNumber && {
        applicationNumber: filters?.applicationNumber
      }
    ];
    setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
  }
  

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Invoices" />
   
          {
            isLoadingInvoices ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                    <TableContainer
                        showView
                        columns={columns}
                        data={invoicesData || []}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        handleView={handleViewDetails}
                        onChangePagination={onChangePagination}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Invoice.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};

export default Invoice;
