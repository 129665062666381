import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from 'moment'
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { getNextOfKinByIdApi, updateNextOfKinApi } from 'api/member';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const NextOfKinData = () => {
  const queryClient = useQueryClient()
  const params = useParams();
  const navigate = useNavigate();

  document.title = "Edit Next of KIN | SUN Welfare Member Portal";

  const [selectedFiles, setselectedFiles] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);

  const selectOption = [
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
    { value: "Husband", label: "Husband" },
    { value: "Wife", label: "Wife" },
    { value: "other", label: "Other" },
  ]

  const {
    mutate: mutateNextOfKins,
    isLoading: isLoadingRegions,
    data: nextOfKinData,
  } = useMutation(payload => getNextOfKinByIdApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("/um/nextofkin/id")
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...nextOfKinData
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      gender: Yup.string().required("Please Select Gender"),
      email: Yup.string().email().required("Please Enter Email"),
      mobileNumber: Yup.string().required("Please Enter Mobile Number"),
      relationship: Yup.string().required("Please Enter Relationship"),
      dob: Yup.date().required("Please Enter DOB"),
    }),
    onSubmit: async values => {
      values.dob = moment(values.dob).format('YYYY-MM-DD');
      saveNextOfKinData(values);
    },
  });

  const handleAcceptedFiles = files => {
    files.forEach(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    validation.setFieldValue(name, value)
  }

  const handleDobChange = e => {
    const { name, value } = e.target
    validation.setFieldValue(name, value)
  }

  const truncatedMobileNumber = validation?.values?.mobileNumber?.slice(0, 10)

  const handleFileChange = e => { }

  useEffect(() => {
    mutateNextOfKins({
      nextOfKinId: params?.id,
    })
  }, []);



  const saveNextOfKinData = async (formData) => {
    try {
      formData.dob = moment(formData.dob).format('YYYY-MM-DD');

      const updatedData = await updateNextOfKinApi(formData);
      console.log("Next of Kin updated successfully!", updatedData)
      setApiResponse(updatedData)
      validation.resetForm()
      setselectedFiles([])
      navigate("/nextofkin")

    } catch (error) {
      console.error("Error editing Next of Kin:", error)
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Form autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}>
            <Breadcrumbs title="Home" breadcrumbItem="Next of KIN Details" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <CardTitle>Edit Next of KIN Details
                      <p className="card-title-desc mb-4">
                        Update Next of KIN Information
                      </p>
                      </CardTitle>
                      <Link to="/nextofkin">
                        <Button type="button" color="primary">
                          <i className="bx bx-arrow-back"></i> Back
                        </Button>
                      </Link>
                    </div>
                    <Row>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="firstName">First Name</Label>
                          <Input
                            id="firstName"
                            name="firstName"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation?.values?.firstName}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="email">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            className={`form-control ${validation.touched.email &&
                              validation.errors.email
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Email"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation?.values?.email}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="birthCertificationNumber">
                            National Identification Number
                          </Label>
                          <Input
                            id="birthCertificationNumber"
                            name="birthCertificationNumber"
                            type="text"
                            className={`form-control ${validation.touched.birthCertificationNumber &&
                              validation.errors.birthCertificationNumber
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="National Identification Document"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.birthCertificationNumber}
                            maxLength={10}
                          />
                          {validation.touched.birthCertificationNumber &&
                            validation.errors.birthCertificationNumber && (
                              <div className="invalid-feedback">
                                {validation.errors.birthCertificationNumber}
                              </div>
                            )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="relationship">Relationship</Label>
                          <Select
                            className="select2"
                            placeholder="Select"
                            options={selectOption}
                            onChange={option =>
                              validation.setFieldValue(
                                "relationship",
                                option.value
                              )
                            }
                            onBlur={() =>
                              validation.setFieldTouched("relationship", true)
                            }
                            value={selectOption.find(
                              option =>
                                option.value === validation.values.relationship
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="lastName">Last Name</Label>
                          <Input
                            id="lastName"
                            name="lastName"
                            type="text"
                            className={`form-control ${validation.touched.lastName &&
                              validation.errors.lastName
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Last Name"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="mobileNumber">Mobile Number</Label>
                          <Input
                            id="mobileNumber"
                            name="mobileNumber"
                            type="tel"
                            className={`form-control ${validation.touched.mobileNumber &&
                              validation.errors.mobileNumber
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="Mobile Number"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={truncatedMobileNumber}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="gender">Gender</Label>
                          <Select
                            options={[
                              { value: "male", label: "Male" },
                              { value: "female", label: "Female" },
                              { value: "other", label: "Other" },
                            ]}
                            onChange={option =>
                              validation.setFieldValue("gender", option.value)
                            }
                            onBlur={() =>
                              validation.setFieldTouched("gender", true)
                            }
                            value={selectOption.find(
                              option =>
                                option.value === validation.values.gender
                            )}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label for="basicpill-pancard-input5">
                            Date Of birth
                          </Label>
                          <Input
                            name="dob"
                            type="date"
                            className="form-control"
                            id="basicpill-pancard-input5"
                            placeholder="Select Date of Birth"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dob || ""}
                            invalid={
                              validation.touched.dob && validation.errors.dob ? true : false
                            }
                          />
                          {validation.touched.dob && validation.errors.dob ? (
                            <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="nextofkinIdentification">
                            Upload Next of KIN Identification Document
                          </Label>
                          <Input
                            type="file"
                            name="nextofkinIdentification"
                            className="form-control"
                            id="nextofkinIdentification"
                            placeholder="Upload Next of KIN Identification Document"
                            onChange={e => handleFileChange(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                        color="primary"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NextOfKinData
