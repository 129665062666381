import { get, post } from './utils';

export async function getAllTransactionApi(data) {
    console.log(data)
    return post(`/transaction/search`, data);
}

export async function getAllInvoicesApi(data) {
    const { memberId } = data;
    return get(`/transaction/invoice/member?memberId=${memberId}`);
}

export async function getAllReceiptsApi(data) {
    return post(`/transaction/receipt/search`, data);
}

export async function requestPayment(data) {
    return post(`/payment`, data);
}

export async function checkPaymentStatus(data) {
    return post(`/payment/status`, data);
}

export async function getReceiptDetailsByIdApi(id) {
    return get(`/transaction/receipt/id?id=${id}`);
}

export async function getInvoiceDetailsByIdApi(id) {
    return get(`/transaction/invoice/id?invoiceId=${id}`);
}