import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useAuth } from "hooks/useAuth";
import { getClaimStatusCountsApi, getDependentsCountByMemberIdApi, getTransactionCountsApi } from "api/report"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

export const sumArray = (arr, key) => {
  return (arr && arr.length) ? arr.reduce((a, b) => a + (b[key] || 0), 0) : 0;
};

const Splinearea = ({ dataColors, claims = [], premiums = [], members = [] }) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "Total dependents",
      data: members?.map(m => m.newDependents) || [],
    },
    {
      name: "Total claims",
      data: claims?.map(c => c.totalClaims) || [],
    },
    {
      name: "Total Payments",
      data: premiums?.map(p => p.totalPremiums) || [],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: spineareaChartColors,
    xaxis: {
      type: "datetime",
      categories: claims.map(c => c.monthEnd),
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "MMM",
      },
    },
    annotations: {
      points: members.map((m, index) => ({
        x: `2018-09-19T${index * 2}:30:00`,
        y: m?.newDependents || 0,
        marker: {
          size: 8,
          fillColor: "#fff",
          strokeColor: "#263238",
          strokeWidth: 2,
        },
        label: {
          borderColor: "#263238",
          offsetY: 0,
          style: {
            color: "#263238",
            background: "#ffffff",
          },
          text: `New Dependents: ${m?.newDependents || 0}`,
        },
      })),
    },
  };

  return <ReactApexChart options={options} series={series} type="area" height="350" />;
};

export default Splinearea;


