import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import TransactionsModal from "./DependantsModal";

import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from "react-router-dom";
import { useAuth } from 'hooks/useAuth';
import { getDependantsApi } from 'api/member';


function Dependants() {
  const { memberId } = useAuth();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const rootPath = '/dependants';

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const onEdit = (record) => {
    navigate(`${rootPath}/${record.original.dependentId}/edit`);
  };
  
  const handleViewDetails = (record) => {
    navigate(`/dependants/${record.original.dependentId}`);
  };

  //meta title
  document.title = "Dependents | SUN Welfare Member System";

  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [transaction] = useState(null);

  const { mutate: mutateDependents,
    isLoading: isLoadingDependents,
    data: dependentsData, } = useMutation(
      (payload) => getDependantsApi(payload),
      {
        onSuccess: res => {
          console.log(res);
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-dependents');
        },
      }
    );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      transactionId: (transaction && transaction.transactionId) || '',
      billingName: (transaction && transaction.billingName) || '',
      transactiondate: (transaction && transaction.transactiondate) || '',
      total: (transaction && transaction.total) || '',

    },
    onSubmit: (values) => {
      if (isEdit) {
        const updatedDependent = {
          firstName: values.firstName,
          lastName: values.lastName,
          relationship: values.relationship,
          dob: values.dob,
          gender: values.gender,
          status: values.status,
          email: values.email,
          mobileNumber: values.mobileNumber,
          birthCertificationNumber: values.birthCertificationNumber,
        };
        dispatch(updateDependentAction(updatedDependent));
      } else {
        const newDependent = {
          firstName: values.firstName,
          lastName: values.lastName,
          relationship: values.relationship,
          dob: values.dob,
          gender: values.gender,
          status: values.status,
          email: values.email,
          mobileNumber: values.mobileNumber,
          birthCertificationNumber: values.birthCertificationNumber,
        };
        dispatch(addNewDependentAction(newDependent));
      }
      validation.resetForm();
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);
  const dispatch = useDispatch();
  const selectTransactionState = (state) => state.transactions;
  const TransactionProperties = createSelector(
    selectTransactionState,
    (Transaction) => ({
      transactions: [],
      loading: Transaction.loading
    })
  );

  const { loading } = useSelector(TransactionProperties);

  const [isLoading, setLoading] = useState(loading)
  const [dependants, setDependants] = useState([]);
  console.log(dependants)


  useEffect(() => {
    if (!isEmpty(dependants) && !!isEdit) {
      setIsEdit(false);
    }
  }, [dependants]);



  useEffect(() => {
    mutateDependents({ memberId, ...pagination });
  }, [pagination]);

 

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="form-check font-size-16" >
          <input className="form-check-input" type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      // {
      //   Header: 'Dependent ID',
      //   accessor: 'dependentId',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <div>{cellProps.value}</div>;
      //   },
      // },
      {
        Header: 'Dependent Names',
        accessor: (row) => `${row.firstName} ${row.lastName}`,
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
      },

      {
        Header: 'Relationship',
        accessor: 'relationship',
        filterable: true,
      },

      {
        Header: 'Date of Birth',
        accessor: 'dob',
        filterable: true,
      },

      {
        Header: 'Gender',
        accessor: 'gender',
        filterable: true,
      },

      {
        Header: 'Status',
        accessor: 'status',
        filterable: true,
      },

      {
        Header: 'Email',
        accessor: 'email',
        filterable: true,
      },

      {
        Header: 'Phone Number',
        accessor: 'mobileNumber',
        filterable: true,
      },

      {
        Header: 'Birth Notification Number',
        accessor: 'birthCertificationNumber',
        filterable: true,
      },

    ],
    [dependentsData]
  );

  const onChangePagination = ({ page, limit }) => {
    setPagination({ ...pagination, pageNumber: page, pageSize: limit });
  };

  return (
    <React.Fragment>
      <TransactionsModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Dependents" />
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        showView
                        // showEdit
                        hasCreate
                        columns={columns}
                        data={dependentsData || []}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        addText="Add Dependent"
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        handleEdit={onEdit}
                        handleView={handleViewDetails}
                        onChangePagination={onChangePagination} 
                      />

                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Dependants.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default Dependants;
