import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainer";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import TransactionsModal from "./NextofkinModal";
import { Col, Row, Card, CardBody } from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'hooks/useAuth';
import { getNextOfKinApi } from 'api/member';

function Nextofkin() {
  const { memberId } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const rootPath = "/nextofkin";
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [Nextofkin, setNextofkin] = useState([]);
  const [selectedNextOfKin, setSelectedNextOfKin] = useState(null);

  const { mutate: mutateNextOfKin,
    isLoading: isLoadingNextOfKin,
    data: nextOfKinData, } = useMutation(
      (payload) => getNextOfKinApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-dependents');
        },
      }
    );
  // Meta title
  document.title = "Next of KIN | SUN Welfare Member System";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const onEdit = (record) => {
    navigate(`${rootPath}/${record.original.nextOfKinId}/edit`);
  };

  const toggleViewModal = () => setModal1(!modal1);

  const selectTransactionState = (state) => state.transactions;

  const TransactionProperties = createSelector(
    selectTransactionState,
    (Transaction) => ({
      transactions: [],
      loading: Transaction.loading,
    })
  );

  const { loading } = useSelector(TransactionProperties);
  const [isLoading, setLoading] = useState(loading);

  useEffect(() => {
    mutateNextOfKin({ memberId, ...pagination });
  }, [pagination]);

  
  const handleViewDetails = (record) => {
    console.log(record);
    navigate(`/nextofkin/${record.original.nextOfKinId}`);
  };
  
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkAll"
            />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: "#",
        width: "20px",
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkAll"
            />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },

      {
        Header: "Full Names",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
        filterable: true,
      },
      {
        Header: "Date of Birth",
        accessor: "dob",
        filterable: true,
      },
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
        filterable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
      },
      {
        Header: "Gender",
        accessor: "gender",
        filterable: true,
      },
      {
        Header: "Relationship",
        accessor: "relationship",
        filterable: true,
      },
    ],
    [nextOfKinData]
  );

  const onChangePagination = ({ page, limit }) => {
    setPagination({ ...pagination, pageNumber: page, pageSize: limit });
  };

  return (
    <React.Fragment>
      <TransactionsModal
        isOpen={modal}
        toggle={toggleViewModal}
        nextOfKinData={selectedNextOfKin}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Next of KIN" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      // showEdit
                      showView
                      hasCreate={!nextOfKinData?.length}
                      // hasCreate
                      columns={columns}
                      data={nextOfKinData || []}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      addText="Add Next of KIN"
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                      handleEdit={onEdit}
                      handleView={handleViewDetails}
                      onChangePagination={onChangePagination} 
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer /> 
    </React.Fragment>
  );
}

Nextofkin.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Nextofkin;
