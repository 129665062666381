import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { getDependantByIdApi, getAttachementByIdApi } from "api/member";
import { useAuth } from 'hooks/useAuth';

const Attachments = () => {
  const { memberId } = useAuth();
  const { id } = useParams();
  const [dependent, setDependent] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const { mutate: mutateDependant, isLoading: isLoadingDependant } =
    useMutation(payload => getDependantByIdApi(payload), {
      onSuccess: (data) => {
        setDependent(data);
      },
      onError: (error) => {
        console.error("Error fetching dependent details:", error);
      },
    }
    );

  useEffect(() => {
    const fetchDependentData = async () => {
      try {
        const dependentDetailsResponse = await getDependantByIdApi({ dependentId: id });
        setDependent(dependentDetailsResponse);

        const attachmentsResponse = await getAttachementByIdApi(id);
        setAttachments(attachmentsResponse);
      } catch (error) {
        console.error("Error fetching claim details:", error);
      }
    };

    fetchDependentData();
  }, [id]);


  // Meta title
  useEffect(() => {
    document.title = "Dependent Attachments | SUN Welfare Member Portal";
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Breadcrumbs
              title="Member Documents "
              breadcrumbItem="Dependent Attachments"
            />
            <Link to="/dependants">
              <Button type="button" color="primary">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
          </div>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <h4>Dependents Details</h4>
                  {dependent ? (
                    <Row>
                      <Col md="6">
                        <p>Full Name:</p>
                        <p>Gender:</p>
                        <p>Status:</p>
                        <p>Relationship:</p>
                      </Col>
                      <Col md="6" style={{ fontWeight: "bold" }}>
                        <p>
                          {dependent.firstName} {dependent.lastName}
                        </p>
                        <p>{dependent.gender}</p>
                        <p>{dependent.status}</p>
                        <p>{dependent.relationship}</p>
                      </Col>
                    </Row>
                  ) : (
                    <div>No Data Found</div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <h4>Dependent Attachments</h4>
                  <Row>
                    {attachments.map((attachment) => (
                      <Col md="6" key={attachment.id}>
                        <div>
                          <span className="me-3">File Name: <strong>{attachment.type}</strong></span>
                          <a
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {attachment.name}
                          </a>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Attachments;










