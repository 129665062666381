import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormGroup,
  Input,
  Label,
  CardTitle,
  Form,
  FormFeedback,
  Button,
  Alert,
} from "reactstrap"
import { useMutation, useQueryClient } from "react-query"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import {
  getAllRegionsApi,
  getAllCountiesByRegionApi,
  getConstituencyApi,
  getAllWardsApi,
} from "api/report"

import { requestTransferApi } from "api/member"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useAuth } from "hooks/useAuth"
import Swal from "sweetalert2"

//redux

const Transfer = props => {
  const queryClient = useQueryClient()
  const [visible, setVisible] = useState(false)

  const navigate = useNavigate()
  //meta title
  document.title = "Request Group Transfer | SUN Welfare Member Portal"

  const {
    user,
    isAuthenticated,
    memberId,
    getCurrentUser,
    transfer,
    loading,
    registrationError,
    registerSuccess,
  } = useAuth()

  useEffect(() => {
    mutateRegions({
      countryId: 1,
    })
  }, [])

  const {
    mutate: mutateRegions,
    isLoading: isLoadingRegions,
    data: regionsData,
  } = useMutation(payload => getAllRegionsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("get-all-counties")
    },
  })

  const {
    mutate: mutateCounty,
    isLoading: isLoadingCounty,
    data: countiesData,
  } = useMutation(payload => getAllCountiesByRegionApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("get-all-counties")
    },
  })

  const {
    mutate: mutateSubCounty,
    isLoading: isLoadingSubCounty,
    data: subCountiesData,
  } = useMutation(payload => getConstituencyApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("get-all-counties")
    },
  })

  const {
    mutate: mutateWards,
    isLoading: isLoadingWards,
    data: wardsData,
  } = useMutation(payload => getAllWardsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("get-all-counties")
    },
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      regionId: "",
      countyId: "",
      constituencyId: "",
      wardId: "",
      comments: "",
      reason: "",
    },
    validationSchema: Yup.object({
      regionId: Yup.string().required("Please Select Region"),
      countyId: Yup.string().required("Please Select County"),
      constituencyId: Yup.string().required("Please Select Constituency"),
      wardId: Yup.string().required("Please Select Ward"),
      reason: Yup.string().required("Please Select a reason for transfer"),
      comments: Yup.string().required("Please provide a reason for transfer"),
    }),

    onSubmit: async ({
      regionId,
      countyId,
      constituencyId,
      wardId,
      comments,
      reason,
      ...values
    }) => {
      console.log(user)
      Swal.fire({
        title: "Requesting Transfer...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
      try {
        console.log("Before API call")
        const groupId = user?.groupId
        const data = {
          fromGroupId: groupId,
          memberId,
          comments,
          destAddress: {
            regionId,
            countyId,
            constituencyId,
            wardId,
          },
          reason,
          ...values,
        }

        await requestTransferApi(data)
          .then(() => {
            console.log("After API call")
            setVisible(true)
            validation.resetForm()
            Swal.fire(
              "Transfer Successful!",
              "Your transfer request was successful.",
              "success"
            )

            navigate("/contacts-profile")
          })
          .catch(err => {
            console.error("API Error:", err)
            Swal.fire(
              "Transfer Request Failed",
              "Your request could not be processed.",
              "error"
            )
          })
      } catch (error) {
        Swal.fire(
          "Transfer Request Failed",
          "Your request could not be processed.",
          "error"
        )
        console.error("Error:", error)
      }finally {
        // Swal.close();  // Ensure the loading alert is closed
     }
    },
  })

  const onChangeRegion = e => {
    const regionId = e.target.value
    validation.setFieldValue("regionId", regionId)
    mutateCounty({
      regionId,
    })
  }

  const onChangeCounty = e => {
    const countyId = e.target.value
    validation.setFieldValue("countyId", countyId)
    mutateSubCounty({
      countyId,
    })
  }

  const onChangeSubCounty = e => {
    const constituencyId = e.target.value
    validation.setFieldValue("constituencyId", constituencyId)
    mutateWards({
      constituencyId,
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Request Group Transfer" />
          <Alert isOpen={visible} toggle={() => setVisible(false)}>
            Member Transfer request submitted Successfully.
          </Alert>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>
                    Please fill in your destination address here
                  </CardTitle>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label for="regionId">Select Region</Label>
                          <Input
                            type="select"
                            className="form-select"
                            name="regionId"
                            id="regionId"
                            value={validation.values.regionId}
                            onChange={onChangeRegion}
                            invalid={
                              validation.touched.regionId &&
                              validation.errors.regionId
                            }
                          >
                            <option value="">Select Region...</option>
                            {regionsData?.map(region => (
                              <option key={region.id} value={region.id}>
                                {region.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.regionId &&
                          validation.errors.regionId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.regionId}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label for="countyId">Select County</Label>
                          <Input
                            type="select"
                            className="form-select"
                            name="countyId"
                            id="countyId"
                            value={validation.values.countyId}
                            onChange={onChangeCounty}
                            invalid={
                              validation.touched.countyId &&
                              validation.errors.countyId
                            }
                          >
                            <option value="">Select County...</option>
                            {countiesData?.map(county => (
                              <option key={county.id} value={county.id}>
                                {county.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.countyId &&
                          validation.errors.countyId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.countyId}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label for="constituencyId">
                            Select Constituency
                          </Label>
                          <Input
                            type="select"
                            className="form-select"
                            name="constituencyId"
                            id="constituencyId"
                            value={validation.values.constituencyId}
                            onChange={onChangeSubCounty}
                            invalid={
                              validation.touched.constituencyId &&
                              validation.errors.constituencyId
                            }
                          >
                            <option value="">Select Constituency...</option>
                            {subCountiesData?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.constituencyId &&
                          validation.errors.constituencyId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.constituencyId}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label for="wardId">Select Ward</Label>
                          <Input
                            type="select"
                            className="form-select"
                            name="wardId"
                            id="wardId"
                            value={validation.values.wardId}
                            onChange={validation.handleChange}
                            invalid={
                              validation.touched.wardId &&
                              validation.errors.wardId
                            }
                          >
                            <option value="">Select Ward...</option>
                            {wardsData?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.wardId &&
                          validation.errors.wardId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.wardId}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="reason">Select Reason for Transfer</Label>
                          <Input
                            type="select"
                            id="reason"
                            name="reason"
                            value={validation.values.reason}
                            onChange={validation.handleChange}
                            invalid={
                              validation.touched.reason &&
                              validation.errors.reason
                            }
                          >
                            <option value="">Select Reason...</option>
                            <option value="Relocation">Relocation</option>
                            <option value="Treatment">Treatment</option>
                            <option value="Job transfer">Job transfer</option>
                          </Input>
                          {validation.touched.reason &&
                          validation.errors.reason ? (
                            <FormFeedback>
                              {validation.errors.reason}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="comments">Description</Label>
                          <Input
                            type="textarea"
                            id="comments"
                            value={validation.values.comments}
                            onChange={validation.handleChange}
                            placeholder="Enter reason for transfer"
                            invalid={
                              validation.touched.comments &&
                              !!validation.errors.comments
                            }
                          />
                          {validation.touched.comments &&
                          validation.errors.comments ? (
                            <FormFeedback>
                              {validation.errors.comments}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                      color="primary"
                      onSubmit={validation.handleSubmit}
                    >
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Transfer
