import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  FormGroup,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,
} from "reactstrap"
import { useMutation, useQueryClient } from "react-query"
import {
  getAllRegionsApi,
  getAllCountiesByRegionApi,
  getConstituencyApi,
  getAllWardsApi,
} from "api/report"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

// //redux
import { useAuth } from "hooks/useAuth"

//redux
import { Link, useNavigate } from "react-router-dom"

const Register = () => {
  const queryClient = useQueryClient()
  const [joinType, setJoinType] = useState("");


  //meta title
  document.title = "Register | SUN Welfare Member Portal"

  const {
    register,
    registrationError,
    registerSuccess,
  } = useAuth()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber1: "",
      regionId: "",
      countyId: "",
      constituencyId: "",
      wardId: "",
      gender: "",
      joinType: "",
      identificationType: "",
      identificationNumber: "",
      dob: "",
      comments: "",
      joinType: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Last Name"),
      mobileNumber1: Yup.string().required("Please Enter Your Mobile Number"),
      regionId: Yup.string().required("Please Select Region"),
      countyId: Yup.string().required("Please Select County"),
      constituencyId: Yup.string().required("Please Select Constituency"),
      wardId: Yup.string().required("Please Select Ward"),
      joinType: Yup.string().required("Please select a registration type"),
    }),

    onSubmit: async ({
      regionId,
      countyId,
      constituencyId,
      wardId,
      ...values
    }) => {
      let data = {
        ...values,
        address: {
          regionId,
          countyId,
          constituencyId,
          wardId,
        },
        source: "web",
        dependents: [],
        nextOfKin: {},
        identificationNumber: '',
        identificationType: '',
        comments: ''
      }
      await register(data).then(() => {
        validation.resetForm();
      })
    },
  })


  useEffect(() => {
    mutateRegions({
      countryId: 1,
    })
  }, []);

  const {
    mutate: mutateRegions,
    data: regionsData,
  } = useMutation(payload => getAllRegionsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("get-all-counties")
    },
  })

  const {
    mutate: mutateCounty,
    data: countiesData,
  } = useMutation(payload => getAllCountiesByRegionApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("get-all-counties")
    },
  })

  const {
    mutate: mutateSubCounty,
    data: subCountiesData,
  } = useMutation(payload => getConstituencyApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("get-all-counties")
    },
  })

  const {
    mutate: mutateWards,
    data: wardsData,
  } = useMutation(payload => getAllWardsApi(payload), {
    onSettled: () => {
      queryClient.invalidateQueries("get-all-counties")
    },
  })

  const onChangeRegion = e => {
    const regionId = e.target.value
    validation.setFieldValue("regionId", regionId)
    mutateCounty({
      regionId,
    })
  }

  const onChangeCounty = e => {
    const countyId = e.target.value
    validation.setFieldValue("countyId", countyId)
    mutateSubCounty({
      countyId,
    })
  }

  const onChangeSubCounty = e => {
    const constituencyId = e.target.value
    validation.setFieldValue("constituencyId", constituencyId)
    mutateWards({
      constituencyId,
    })
  }



  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={6}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4 text-white" >
                        <span className="font-size-14">Join SHOFCO SUN Welfare</span>
                        <p>Register for free today.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {registerSuccess && (
                        <Alert color="success">
                          Request successful. We'll process your request and get
                          back to you.
                        </Alert>
                      )}
                      {registrationError && registrationError ? (
                        <Alert color="danger">
                          {registrationError.message}
                        </Alert>
                      ) : null}
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <Input
                              name="firstName"
                              type="text"
                              placeholder="Enter first name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstName || ""}
                              invalid={
                                validation.touched.firstName &&
                                validation.errors.firstName
                              }
                            />
                            {validation.touched.firstName &&
                              validation.errors.firstName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.firstName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              name="lastName"
                              type="text"
                              placeholder="Enter last name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastName || ""}
                              invalid={
                                validation.touched.lastName &&
                                  validation.errors.lastName
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                  validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                              validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">Mobile Number</Label>
                            <Input
                              name="mobileNumber1"
                              id="mobileNumber1"
                              type="text"
                              placeholder="Enter mobile number"
                              onChange={e => {
                                const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                                validation.handleChange({ target: { name: 'mobileNumber1', value } });
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.mobileNumber1}
                              invalid={
                                validation.touched.mobileNumber1 &&
                                  validation.errors.mobileNumber1
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mobileNumber1 &&
                              validation.errors.mobileNumber1 ? (
                              <FormFeedback type="invalid">
                                {validation.errors.mobileNumber1}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="reason">Registration Type</Label>
                            <Input
                              type="select"
                              id="joinType"
                              name="joinType"
                              value={validation.values.reason}
                              onChange={validation.handleChange}
                              invalid={validation.touched.reason && validation.errors.reason}
                            >
                              <option value="">Select Registration Type...</option>
                              <option value="Individual">Individual Registration</option>
                              <option value="Group">Group Registration</option>
                            </Input>
                            {validation.touched.reason && validation.errors.reason ? (
                              <FormFeedback>{validation.errors.reason}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="mb-3">
                            <Label for="regionId">Select Region</Label>
                            <Input
                              type="select"
                              className="form-select"
                              name="regionId"
                              id="regionId"
                              value={validation.values.regionId}
                              onChange={onChangeRegion}
                              invalid={
                                validation.touched.regionId &&
                                validation.errors.regionId
                              }
                            >
                              <option value="">Select Region...</option>
                              {regionsData?.map(region => (
                                <option key={region.id} value={region.id}>
                                  {region.name}
                                </option>
                              ))}
                            </Input>
                            {validation.touched.regionId &&
                              validation.errors.regionId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.regionId}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="mb-3">
                            <Label for="countyId">Select County</Label>
                            <Input
                              type="select"
                              className="form-select"
                              name="countyId"
                              id="countyId"
                              value={validation.values.countyId}
                              onChange={onChangeCounty}
                              invalid={
                                validation.touched.countyId &&
                                validation.errors.countyId
                              }
                            >
                              <option value="">Select County...</option>
                              {countiesData?.map(county => (
                                <option key={county.id} value={county.id}>
                                  {county.name}
                                </option>
                              ))}
                            </Input>
                            {validation.touched.countyId &&
                              validation.errors.countyId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.countyId}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="mb-3">
                            <Label for="constituencyId">
                              Select Constituency
                            </Label>
                            <Input
                              type="select"
                              className="form-select"
                              name="constituencyId"
                              id="constituencyId"
                              value={validation.values.constituencyId}
                              onChange={onChangeSubCounty}
                              invalid={
                                validation.touched.constituencyId &&
                                validation.errors.constituencyId
                              }
                            >
                              <option value="">Select Constituency...</option>
                              {subCountiesData?.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                            {validation.touched.constituencyId &&
                              validation.errors.constituencyId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.constituencyId}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="mb-3">
                            <Label for="wardId">Select Ward</Label>
                            <Input
                              type="select"
                              className="form-select"
                              name="wardId"
                              id="wardId"
                              value={validation.values.wardId}
                              onChange={validation.handleChange}
                              invalid={
                                validation.touched.wardId &&
                                validation.errors.wardId
                              }
                            >
                              <option value="">Select Ward...</option>
                              {wardsData?.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                            {validation.touched.wardId &&
                              validation.errors.wardId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.wardId}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="mt-4">
                        <Button
                          block
                          className="btn bg-primary btn-block "
                          type="submit"
                        >
                          Request Join
                        </Button>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree SHOFCO Welfare{" "}
                          <Link to="https://shofcowelfare.org/terms" className="text-primary">
                            Terms & Conditions
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} SHOFCO SUN Welfare Program{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
