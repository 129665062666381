import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from 'react-query';

import {
  Card,
  CardBody,
  Col,
  CardTitle,
  Form,
  Container,
  Input,
  NavItem,
  NavLink,
  Button,
  Row,
  TabContent,
  TabPane,
  Label,
  Spinner, FormGroup,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { requestPayment, checkPaymentStatus } from 'api/transaction';
import { getMemberByIdApi } from 'api/member'
import Swal from 'sweetalert2';


const paymentPeriods = [
  { id: 1, name: 'Registration', value: 300 },
  { id: 2, name: '1 Month', value: 100 },
  { id: 3, name: '3 Months', value: 300 },
  { id: 4, name: '6 Months', value: 600 },
  { id: 5, name: '1 Year', value: 1200 }
];

const FormWizard = () => {
  document.title = "Payments | SUN Welfare Member Portal";

  const [activeTab, setactiveTab] = useState(1);
  const [amount, setAmount] = useState(100);
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const queryClient = useQueryClient();

  const { mutate: mutateMember, data: member } = useMutation(
      (payload) => getMemberByIdApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
  );

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    const memberId = localStorage.getItem('memberId');
    if (memberId) {
      getMemberData(memberId);
    } else {
      console.error('Member ID not found in local storage');
    }
  }, []);

  const getMemberData = async (memberId) => {
    try {
      const memberData = await getMemberByIdApi(memberId);
      setMobileNumber(memberData.mobileNumber1.replace(/\D/g, ''));
    } catch (error) {
      console.error('Error fetching member data:', error);
    }
  };

  useEffect(() => {
    mutateMember(localStorage.getItem('memberId'));
  }, []);

  const onChangeAmount = (e) => {
    const amt = Number(e.target.value);
    setAmount(amt);
    validation.setFieldValue('amount', amt);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobileNumber: mobileNumber || '',  // Auto-filled phone number
      amount: amount || ''
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string().required("Please Enter Mobile Number"),
      amount: Yup.string().required("Please Enter Amount"),
    }),
    onSubmit: async (values) => {
      const mobile = values.mobileNumber.replace(/\D/g, '');
      const isRegistration = values.amount == 200;

      // setStatusMessage('Processing payment...');
      Swal.fire({
        title: 'Processing payment...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      // toggleModal();

      try {
        const paymentResponse = await handleRequestPayment({
          userId: member.memberId,
          clientMobile: `254${mobile.slice(-9)}`, // Format phone number correctly
          description: isRegistration ? 'Sun Registration Payment' : "Sun Premium Payment",
          amount: values.amount,
          platform: "WEB",
          paymentMethod: "MPESA"
        });

        console.log('Payment Response:', paymentResponse);

        const { merchantRequestId, checkoutRequestID } = paymentResponse.data.data;
        const statusResponse = await requestPaymentStatus({
          merchantRequestId,
          checkoutRequestId: checkoutRequestID
        });

        console.log('Payment Status Response:', statusResponse.data.transactionStatus);

        const transactionStatus = statusResponse.data.transactionStatus.toLowerCase();

        if (transactionStatus === 'success') {
          Swal.fire('Payment Successful!', 'Your payment was successful.', 'success');
        } else {
          Swal.fire('Payment Failed', 'Your payment could not be processed.', 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'An error occurred while processing payment.', 'error');
        console.error('Payment Error:', error);
      } 
    },
  });

  const handleRequestPayment = async (paymentData) => {
    try {
      setLoading(true);
      const response = await requestPayment(paymentData);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  const requestPaymentStatus = async (statusData) => {
    try {
      const response = await checkPaymentStatus(statusData);
      return response;
    } catch (error) {
      console.error('Payment Status Check Error:', error);
      throw error;
    }
  };

  const stepStyle = {
    borderRadius: "50%",
    border: "2px solid #333",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Payments" breadcrumbItem="New Payment" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h4 className="card-title mb-0">Make Payment</h4>
                      <Link to="/payments">
                        <Button type="button" color="primary">
                          <i className="bx bx-arrow-back"></i> Back
                        </Button>
                      </Link>
                    </div>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem className={classnames({ current: activeTab === 1 })}>
                            <NavLink className={classnames({ current: activeTab === 1 })} onClick={() => setactiveTab(1)}>
                              <span className="number"></span> Mpesa Express
                            </NavLink>
                          </NavItem>
                          {/*<NavItem className={classnames({ current: activeTab === 2 })}>*/}
                          {/*  <NavLink className={classnames({ current: activeTab === 2 })} onClick={() => setactiveTab(2)}>*/}
                          {/*    <span className="number"></span> Mpesa Standard*/}
                          {/*  </NavLink>*/}
                          {/*</NavItem>*/}
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body mx-auto" style={{ maxWidth: "50%" }}>
                          <TabPane tabId={1}>
                            <Row>
                              <ol style={{ listStyle: "none", padding: 0 }}>
                                <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                  <div style={stepStyle}>1</div>
                                  Enter the phone number you wish to use to pay with
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                  <div style={stepStyle}>2</div>
                                  Check your phone number and enter your <br /> Mpesa PIN on your phone to complete the payment
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                  <div style={stepStyle}>3</div>
                                  You will get your receipt from MPESA
                                </li>
                              </ol>
                              <CardTitle className="h4 mt-4">Request Payment</CardTitle>
                              <Form autoComplete="off" onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); }}>
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label for="mobileNumber">Phone Number</Label>
                                      <Input
                                          type="text"  // Changed to text to handle non-numeric input
                                          id="mobileNumber"
                                          name="mobileNumber"
                                          placeholder="Enter phone number"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.mobileNumber || ""}
                                          invalid={validation.touched.mobileNumber && validation.errors.mobileNumber ? true : false}
                                      />
                                      {validation.touched.mobileNumber && validation.errors.mobileNumber ? (
                                          <FormFeedback type="invalid">{validation.errors.mobileNumber}</FormFeedback>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label>Select Period</Label>
                                      <Input
                                          type='select'
                                          className="form-select"
                                          name="amount"
                                          onChange={onChangeAmount}
                                          value={validation.values.amount || ""}
                                          invalid={validation.touched.amount && validation.errors.amount ? true : false}
                                      >
                                        {paymentPeriods.map(item => (
                                            <option key={item.id} value={item.value}>
                                              {item.name}
                                            </option>
                                        ))}
                                      </Input>
                                      {validation.touched.amount && validation.errors.amount ? (
                                          <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row><h5> Amount to Pay Kes {validation.values.amount}</h5></Row>
                                <FormGroup>
                                  <Button color="primary" block type="submit" disabled={loading}>
                                    {loading ? <Spinner size="sm" color="light" /> : 'Request Payment'}
                                  </Button>
                                </FormGroup>
                                <div>
                                  <ol style={{ fontSize: '1.2em', listStyleType: 'none', paddingLeft: '0' }}>
                                    <li style={{ position: 'relative', paddingLeft: '30px', marginBottom: '15px' }}>
                                    <span style={{ position: 'absolute', left: '0', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: '#00b4e4', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      1
                                    </span>
                                      You will shortly receive an M-PESA prompt on your phone requesting you to enter your M-PESA PIN to complete your payment.
                                    </li>
                                    <li style={{ position: 'relative', paddingLeft: '30px', marginBottom: '15px' }}>
                                    <span style={{ position: 'absolute', left: '0', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: '#00b4e4', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      2
                                    </span>
                                      Please ensure your phone is on and unlocked to enable you to complete the process.
                                    </li>
                                    <li style={{ position: 'relative', paddingLeft: '30px', marginBottom: '15px' }}>
                                    <span style={{ position: 'absolute', left: '0', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: '#00b4e4', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      3
                                    </span>
                                      In case you experience difficulties receiving Mpesa express prompt, you can also pay using Lipa na MPESA by using Mpesa Standard. Thank you.
                                    </li>
                                  </ol>
                                </div>
                              </Form>
                            </Row>
                          </TabPane>
                          <TabPane tabId={2}>
                            <ol style={{ listStyle: "none", padding: 0 }}>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <div style={stepStyle}>1</div>
                                Go to Safaricom Menu
                              </li>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <div style={stepStyle}>2</div>
                                Select M-PESA
                              </li>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <div style={stepStyle}>3</div>
                                Select Lipa na MPESA
                              </li>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <div style={stepStyle}>4</div>
                                Select Paybill
                              </li>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <div style={stepStyle}>5</div>
                                Enter Business No: 4068281
                              </li>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <div style={stepStyle}>6</div>
                                Enter Account No: Your ID Number
                              </li>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <div style={stepStyle}>7</div>
                                Enter Amount
                              </li>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <div style={stepStyle}>8</div>
                                Complete Payment
                              </li>
                            </ol>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Payment Status</ModalHeader>
          <ModalBody>
            <p>{statusMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
}

export default FormWizard;








