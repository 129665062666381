import React, { useState, useEffect, useContext } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import { StoreContext } from "../../hooks/useStore"
import { sendEmailForMFA, resetPasswordApi } from 'api/auth';

const UserProfile = () => {
  // meta title
  document.title = "Profile | SUN Welfare Administration System"

  const dispatch = useDispatch()

  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);


  const { state } = useContext(StoreContext)

  const selectProfileState = state => state.Profile
  const ProfileProperties = createSelector(selectProfileState, profile => ({
    error: profile.error,
    success: profile.success,
  }))

  const { error, success } = useSelector(ProfileProperties)

  useEffect(() => {
    const userData = localStorage.getItem("user");

    if (userData) {
      const user = JSON.parse(userData);

      if (user && user.firstName) {
        setFirstName(user.firstName);
      }
      if (user && user.lastName) {
        setLastName(user.lastName);
      }
    }
  }, []); 

  useEffect(() => { }, [])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: values => {
      dispatch(editProfile(values))
    },
  })

  const handleResetPassword = async () => {
    try {
      const userEmail = localStorage.getItem("shofco_email");
      const response = await sendEmailForMFA(userEmail);
      if (response && response.status === "Success") {
        setShowResetPasswordModal(true);
      } else {
        console.error("Failed to send OTP:", response.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  const handleSubmitResetPassword = async values => {
    try {
      await resetPasswordApi({
        userName: values.email,
        password: values.newPassword,
        code: values.otp,
      })
      console.log("Password reset successful")
    } catch (error) {
      console.error("Password reset error:", error)
    }
  }

  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="User" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">
                          EMAIL: {localStorage.getItem("shofco_email")}
                        </p>
                        <p className="mb-1">
                          USER NAME: {firstName} {lastName}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
