import { get, post } from './utils';

export async function getAllRegionsApi(data) {
    return get(`/public/um/region`);
}

export async function getAllCountiesApi(data) {
    return get(`/public/um/county/country`, data);
}
export async function getAllCountiesByRegionApi(data) {
    return get(`/public/um/county`, data);
}

export async function getConstituencyApi(data) {
    return get(`/public/um/constituency`, data);
}

export async function getAllWardsApi(data) {
    return get(`/public/um/ward`, data);
}
export async function getAllRelationshipsApi(data) {
    return get(`/public/um/setup/relationship`);
}
export async function getClaimStatusCountsApi(data) {
    return post(`/report/claim/counts`, data);
}

export async function getTransactionCountsApi(data) {
    return post(`/report/transaction/counts`, data);
}


export async function getDependentsCountByMemberIdApi(data) {
    return post(`/report/member/counts`, data);
}