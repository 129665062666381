// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Row,
// } from "reactstrap";
// import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
// import { useMutation } from "react-query";
// import { getNextOfKinApi, getNextOfKindetailsByIdApi } from "api/member";

// const Attachments = () => {
//   const { id } = useParams();
//   const [nextOfKinData, setNextOfKinData] = useState(null);
//   const [attachments, setAttachments] = useState([]);


//   // const { mutate: mutateNextOfKin, isLoading: isLoadingNextOfKin } =
//   //   useMutation(payload => getNextOfKinApi(payload), {
//   //     onSuccess: data => {
//   //       setNextOfKinData(data);
//   //       console.log(data)
//   //     },
//   //     onError: error => {
//   //       console.error("Error fetching next of kin details:", error);
//   //     },
//   //   });

//     const { mutate: mutateNextOfKint, isLoading: isLoadingNextOfKin } =
//     useMutation(payload => getNextOfKinApi(payload), {
//       onSuccess: (data) => {
//         setNextOfKinData(data);
//       },
//       onError: (error) => {
//         console.error("Error fetching dependent details:", error);
//       },
//     }
//     );

//   // useEffect(() => {
//   //   const fetchNextOfKinData = async () => {
//   //     try {
//   //       const nextOfKin = await getNextOfKindetailsByIdApi(id);
//   //       setNextOfKinData(nextOfKin);
//   //     } catch (error) {
//   //       console.error("Error fetching next of kin details:", error);
//   //     }
//   //   };

//   //   fetchNextOfKinData();
//   // }, [id]);


//   useEffect(() => {
//     const fetchNextOfKinData  = async () => {
//       try {
//         const nextOfKinDataResponse = await getNextOfKindetailsByIdApi(id);
//         setNextOfKinData(nextOfKinDataResponse);

//         const attachmentsResponse = await getAttachementByIdApi(id);
//         setAttachments(attachmentsResponse);
//       } catch (error) {
//         console.error("Error fetching claim details:", error);
//       }
//     };

//     fetchNextOfKinData();
//   }, [id]);



//   useEffect(() => {
//     document.title = "Next of Kin Attachments | SUN Welfare Member Portal";
//   }, []);

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid={true}>
//           <div className="d-flex justify-content-between align-items-center mb-4">
//             <Breadcrumbs
//               title="Member Documents "
//               breadcrumbItem="Next of Kin Attachments"
//             />
//             <Link to="/nextofkin">
//               <Button type="button" color="primary">
//                 <i className="bx bx-arrow-back"></i> Back
//               </Button>
//             </Link>
//           </div>
//           <Row>
//             <Col lg="6">
//               <Card>
//                 <CardBody>
//                   <h4>Next Of Kin Details</h4>
//                   <Row>
//                     <Col md="6">
//                       <p>Full Name:</p>
//                       <p>Mobile:</p>
//                       <p>Email:</p>
//                       <p>Relationship:</p>
//                     </Col>
//                     <Col md="6" style={{ fontWeight: "bold" }}>
//                       {nextOfKinData ? (
//                         <>
//                           <p>{nextOfKinData.firstName} {nextOfKinData.lastName}</p>
//                           <p>{nextOfKinData.mobileNumber}</p>
//                           <p>{nextOfKinData.email}</p>
//                           <p>{nextOfKinData.relationship}</p>
//                         </>
//                       ) : (
//                         <p>No Data Found</p>
//                       )}
//                     </Col>
//                   </Row>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col lg="6">
//               <Card>
//                 <CardBody>
//                   <h4>Next Of Kin Attachments</h4>
//                   <Row>
//                     {attachments.map((attachment) => (
//                       <Col md="6" key={attachment.id}>
//                         <div>
//                           <span className="me-3">File Name: <strong>{attachment.type}</strong></span>
//                           <a
//                             href={attachment.url}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                           >
//                             {attachment.name}
//                           </a>
//                         </div>
//                       </Col>
//                     ))}
//                   </Row>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Attachments;


import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { getNextOfKinApi, getNextOfKindetailsByIdApi, getAttachementByIdApi } from "api/member";

const Attachments = () => {
  const { id } = useParams();
  const [nextOfKinData, setNextOfKinData] = useState(null);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    document.title = "Next of Kin Attachments | SUN Welfare Member Portal";
  }, []);

  useEffect(() => {
    const fetchNextOfKinData = async () => {
      try {
        const nextOfKinDataResponse = await getNextOfKindetailsByIdApi(id);
        setNextOfKinData(nextOfKinDataResponse);

        const attachmentsResponse = await getAttachementByIdApi(id);
        setAttachments(attachmentsResponse);
      } catch (error) {
        console.error("Error fetching next of kin details:", error);
      }
    };

    fetchNextOfKinData();
  }, [id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Breadcrumbs
              title="Member Documents "
              breadcrumbItem="Next of Kin Attachments"
            />
            <Link to="/nextofkin">
              <Button type="button" color="primary">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
          </div>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <h4>Next Of Kin Details</h4>
                  <Row>
                    <Col md="6">
                      <p>Full Name:</p>
                      <p>Mobile:</p>
                      <p>Email:</p>
                      <p>Relationship:</p>
                    </Col>
                    <Col md="6" style={{ fontWeight: "bold" }}>
                      {nextOfKinData ? (
                        <>
                          <p>{nextOfKinData.firstName} {nextOfKinData.lastName}</p>
                          <p>{nextOfKinData.mobileNumber}</p>
                          <p>{nextOfKinData.email}</p>
                          <p>{nextOfKinData.relationship}</p>
                        </>
                      ) : (
                        <p>No Data Found</p>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <h4>Next Of Kin Attachments</h4>
                  <Row>
                    {attachments.map((attachment) => (
                      <Col md="6" key={attachment.id}>
                        <div>
                          <span className="me-3">File Name: <strong>{attachment.type}</strong></span>
                          <a
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {attachment.name}
                          </a>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Attachments;

