import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-dark2.png";
import { useMutation } from "react-query";
import { getDeceasedDetailsByClaimId } from "api/member";
import { getClaimAttachmentsByIdApi } from "api/claim";
import moment from "moment";

const ClaimDetail = () => {
  const { id } = useParams();
  const [claimDetails, setClaimDetails] = useState(null);
  const [attachmentData, setAttachmentData] = useState([]);
  const { isLoading: isLoadingClaimDetails } = useMutation(
    getDeceasedDetailsByClaimId,
    {
      onSuccess: (data) => {
        setClaimDetails(data);
      },
      onError: (error) => {
        console.error("Error fetching claim details:", error);
      },
    }
  );

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const claimDetailsResponse = await getDeceasedDetailsByClaimId(id);
        setClaimDetails(claimDetailsResponse);

        const attachmentsResponse = await getClaimAttachmentsByIdApi(id);
        setAttachmentData(attachmentsResponse);
      } catch (error) {
        console.error("Error fetching claim details:", error);
      }
    };

    fetchClaimData();
  }, [id]);

  // Meta title
  useEffect(() => {
    document.title = "Claim Details | Your Application Name";
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Breadcrumbs title="Claims" breadcrumbItem="Claim Details" />
            <Link to="/claims">
              <Button type="button" color="primary">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
          </div>
          <Row>
            {isLoadingClaimDetails ? (
              <div>Loading...</div>
            ) : (
              <Col lg="12">
                <Card style={{ maxWidth: "70%", margin: "0 auto" }}>
                  <CardBody>
                    <Row>
                      <Col md={4}>
                        <div className="mb-4">
                          <img
                            src={logo}
                            alt="Company Logo"
                            style={{ maxWidth: "200px" }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <h5>Claim Status</h5>
                        <p>{claimDetails?.claimStatus?.status || "Data not found"}</p>
                      </Col>
                      <Col md="4">
                        <h5>Date Reported</h5>
                        <p>{claimDetails?.reportedDateTime ? moment(claimDetails.reportedDateTime).format("MMM DD, YYYY HH:mm") : "Data not found"}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <Row>
                              <h4>Deceased Details</h4>
                              <Col md="6">
                                <p>Full Names:</p>
                                <p>Date of Death:</p>
                                <p>Burial Permit Serial Number:</p>
                              </Col>
                              <Col md="6" style={{ fontWeight: "bold" }}>
                                <p>
                                  {claimDetails?.deathCertificate
                                    ? `${claimDetails?.deathCertificate?.firstName || "Data not found"} ${claimDetails?.deathCertificate?.lastName || ""}`
                                    : "Data not found"}
                                </p>
                                <p>
                                  {claimDetails?.deathCertificate
                                    ? `${moment(claimDetails?.deathCertificate?.deathDate).format("MMM DD, YYYY")}`
                                    : "Data not found"}
                                </p>
                                <p>
                                  {claimDetails?.deathCertificate
                                    ? `${moment(claimDetails?.deathCertificate?.burialPermitSerialNumber || "Data not found")} ${claimDetails?.deathCertificate || ""}`
                                    : "Data not found"}
                                </p>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <h4>Claim Attachments:</h4>
                            <Row>
                              {attachmentData.map((attachment) => (
                                <Col md="6" key={attachment.id}>
                                  <div>
                                    <span className="me-3">Name: <strong>{attachment.type}</strong></span>
                                    <a
                                      href={attachment.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {attachment.name}
                                    </a>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6"></Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClaimDetail;








