import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';


//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import { useAuth } from 'hooks/useAuth';
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { useMutation, useQueryClient } from 'react-query';
import { getAllReceiptsApi } from 'api/transaction';
import { getMemberByIdApi } from 'api/member';
import {useNavigate} from "react-router-dom";


function Transaction() {
  const queryClient = useQueryClient();
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { memberId } = useAuth();
  const { t, i18n } = useTranslation();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    searchColumns: [],
  });
  const navigate = useNavigate();

  const handleViewDetails =(record) =>{
    console.log(record)
    navigate(`/receipts/${record.original.id}`);
  }

  const { mutate: mutateReceipts,
    isLoading: isLoadingReceipts,
    data: receiptsData, } = useMutation(
      (payload) => getAllReceiptsApi(payload),
      {
        onSuccess: res => {
          res.pages > 0 && setPages(res.pages);
          console.log(res)
        },
        onSettled: () => {
          setLoading(false);
          queryClient.invalidateQueries('get-receipts');
        },
      }
    );

  useEffect(() => {
    mutateReceipts({
      ...pagination,
      pageNumber: pagination.pageNumber - 1,
      searchColumns: [
        {
          name: "memberId",
          value: memberId
        }
      ]
    });
  }, [pagination]);

  //meta title
  document.title = "Transactions | SUN Welfare Member System";


  const onChangePagination = (
    { page, limit }, filters, sorter) => {

    console.log(filters)
    let searchColumns = isEmpty(filters) ? [] : [
      filters?.id && {
        id: filters?.id
      },
      filters?.applicationNumber && {
        applicationNumber: filters?.applicationNumber
      }
    ];
    setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Receipt Number',
        accessor: 'receiptNumber',
      },
      {
        Header: 'Member Name',
        accessor: 'memberId',
        filterable: true,
        Cell: (cellProps) => {
          const [memberDetails, setMemberDetails] = useState(null);

          useEffect(() => {
            const fetchMemberDetails = async () => {
              const memberId = cellProps.row.original.memberId;
              if (memberId) {
                try {
                  const details = await getMemberByIdApi(memberId);
                  setMemberDetails(details);
                } catch (error) {
                  console.error('Error fetching member details:', error);
                }
              }
            };

            fetchMemberDetails();
          }, [cellProps.row.original.toMemeberId]);

          return <span>{memberDetails ? `${memberDetails.firstName} ${memberDetails.lastName}` : 'Loading...'}</span>;
        },
      },

      {
        Header: 'Receipt Date',
        accessor: 'receiptDateTime',
        filterable: true,
        Cell: (cellProps) => {
          const formattedDate = moment(cellProps.value).format('YYYY MMM DD h:mm A');
          return <span>{formattedDate}</span>;
        }
      },
      {
        Header: 'Amount Paid',
        accessor: 'amount',
      },

    ]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Receipts" />
          {
            isLoadingReceipts ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        showView
                        columns={columns}
                        data={receiptsData || []}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        handleView={handleViewDetails}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        onChangePagination={onChangePagination}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Transaction.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};

export default Transaction;
