import {
  PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG,
  GET_USER_PROFILE, GET_USER_PROFILE_FAIL, GET_USER_PROFILE_SUCCESS
} from "./actionTypes";

const initialState = {
  error: "",
  userProfile: {},
  success: "",
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    case GET_USER_PROFILE:
      state = { ...state };
      break;

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
