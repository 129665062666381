import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import RegistrationSuccess from "pages/Authentication/RegistrationSuccess";

// Dashboard
import Dashboard from "../pages/Dashboard";


//Pages
import Dependants from "../pages/Dependants";
import Payments from "../pages/Payments";
import Claims from "../pages/Claims";
import Nextofkin from "pages/NextofKin";
import AddDependant from "../pages/Dependants/AddDependant";
import EditDependant from "../pages/Dependants/EditDependant";
import AddPayment from "../pages/Payments/AddPayment";
import AddNextofkin from "../pages/NextofKin/AddNextofkin";
import EditNextofkin from "pages/NextofKin/EditNextofkin";
import ClaimDetail from "pages/Claims/ClaimDetails";
import Attachments from "pages/NextofKin/Attachments";
import Attachements from "pages/Dependants/Attachements";
import Invoices from "pages/Invoices";
import InvoiceDetails from "pages/Invoices/InvoiceDetails";
import Receipts from "pages/Receipts";
import ReceiptDetails from "pages/Receipts/ReceiptDetails";


//Contacts
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";
import ContactsTransfer from "../pages/Contacts/ContactsProfile/ContactsTrnasfer";
import { components } from "react-select";



const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/user-profile", component: <UserProfile /> },

  { path: "/attachments", component: <Attachments /> },
  { path: "/attachements", component: <Attachements /> },

  //pages
  { path: "/claims", component: <Claims /> },
  { path: "/claims/:id", component:<ClaimDetail/> },
  
  { path: "/invoices/:id", component: <InvoiceDetails /> },
  { path: "/invoices", component: <Invoices /> },

  { path: "/receipts/:id", component: <ReceiptDetails /> },
  { path: "/receipts", component: <Receipts /> },
  
  //Dependants
  { path: "/dependants", component: <Dependants/>},
  { path: "/dependants/create", component: <AddDependant /> },
  { path: "/editdependant/:id", component: <EditDependant /> },
  { path: "dependants/:id/edit", component: <EditDependant /> },
  { path: "dependants/:id", component: <Attachements/> },

  //Next of KIN
  { path: "/nextofkin", component: <Nextofkin /> },
  { path: "/nextofkin/create", component: <AddNextofkin /> },
  { path: "/addnextofkin", component: <AddNextofkin /> },
  { path: "/nextofkin/:id/edit", component: <EditNextofkin /> },
  { path: "/nextofkin/:id", component:<Attachments/> },

  //Payments
  { path: "/payments", component: <Payments /> },
  { path: "/payments/create", component: <AddPayment /> },
  { path: "/addpayment", component: <AddPayment /> },

  // Contacts
  { path: "/contacts-profile", component: <ContactsProfile /> },
  { path: "/contactstransfer", component: <ContactsTransfer />},

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/registrationsuccess", component: <RegistrationSuccess/>},
];

export { authProtectedRoutes, publicRoutes };
