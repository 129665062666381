import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import moment from "moment";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "flatpickr/dist/themes/material_blue.css";
import {Date,} from "./ClaimCol";
import { useNavigate } from "react-router-dom"; 
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import ApplicationModal from "./ClaimModal";

import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { getAllClaimApi } from 'api/claim';
import { useAuth } from 'hooks/useAuth';

function Claims(props) {
  const { memberId } = useAuth();
  const queryClient = useQueryClient()

  const { mutate: mutateClaims,
    isLoading: isLoadingClaims,
    data: claimsData, } = useMutation(
      (payload) => getAllClaimApi(payload)
      ,
      {
        onSuccess: res => {
          console.log(res);
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-dependents');
        },
      }
    );


  //meta title
  document.title = "Claims |  Welfare Member System";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [application, setApplication] = useState(null);
  const navigate = useNavigate();

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      applicationId: (application && application.applicationId) || '',
      billingName: (application && application.billingName) || '',

    },

    onSubmit: (values) => {
      if (isEdit) {
        const updateApplication = {
          id: application ? application.id : 0,
          applicationId: values.applicationId,
          billingName: values.billingName,
          total: values.total,
        };
        dispatch(onUpdateApplication(updateApplication));
        validation.resetForm();
      } else {
        const newApplication = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          applicationId: values["applicationId"],
          billingName: values["billingName"],
          applicationdate: values["applicationdate"],
          total: values["total"],

        };
        dispatch(onAddNewApplication(newApplication));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectApplicationState = (state) => state.applications;
  const ApplicationApplicationProperties = createSelector(
    selectApplicationState,
    (Application) => ({
      applications: [],
      loading: Application.loading
    })
  );

  const { applications, loading } = useSelector(ApplicationApplicationProperties);
  const [isLoading, setLoading] = useState(loading || false);

  useEffect(() => {
    mutateClaims({
      pageSize: 100,
      pageNumber: 0,
      sortBy: "claimNumber",
      searchColumns: [
        {
         name: "memberId",
         value: memberId 
        }
      ]
    });
  }, []);



  const handleViewDetails = (record) => {
    navigate(`/claims/${record.original.claimStatus.claimId}`);
  };
  

  
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: 'Deceased Names',
        accessor: (row) => `${row.deathCertificate.firstName || ''} ${row.deathCertificate.lastName || ''}`,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Date of Death',
        accessor: 'deathCertificate.deathDate',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
        filterable: true,
        Cell: (cellProps) => {
          const dateValue = cellProps.value;
          const formattedDate = dateValue ? moment(dateValue).format('YYYY MMM DD h:mm A') : '';
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: 'Relationship',
        accessor: 'type',
        filterable: true,
      },
      {
        Header: 'Burial Permit Serial Number',
        accessor: 'burialPermitSerialNumber',
        filterable: true,
      },

      {
        Header: 'Status',
        accessor: 'claimStatus.status',
        filterable: true,
      },

    ],
    [claimsData]
  );


  return (
    <React.Fragment>
      <ApplicationModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="My Claims" />

          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        showView
                        columns={columns}
                        data={claimsData?.list || []}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        handleView={handleViewDetails}
                        // onChangePagination={onChangePagination} 
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Claims.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Claims;



